const accountOverviewRoute = () => import('../components/routes/account-overview/CsoAccountOverviewRouteStatusPage.vue')
const consumptionOverviewRoute = () => import('../components/routes/consumption-overview/CsoConsumptionOverviewRoute.vue')
const personalDataRoute = () => import('../components/routes/personal-data/CsoPersonalDataRoute.vue')
const tellAFriendRoute = () => import('../components/routes/tell-a-friend/CsoTellAFriendProcess.vue')
const contractAndDeliveryRoute = () => import('../components/routes/contract-and-delivery/CsoContractAndDeliveryOverview.vue')
const communicationRoute = () => import('../components/routes/communication/CsoCommunicationRoute.vue')
const tariffDetailsRoute = () => import('../components/routes/tariff-details/CsoTariffDetailsRouteStatusPage.vue')
const optinProcessRoute = () => import('../components/routes/optin-process/CsoOptinRouteStatusPage.vue')
const meterReadingClosedRoute = () => import('../components/routes/meter-reading/CsoMeterReadingClosedRouteStatusPage.vue')
const bankDataRoute = () => import('../components/routes/bank-data/CsoBankDataRouteStatusPage.vue')
const partPaymentRoute = () => import('../components/routes/part-payment/CsoPartPaymentRouteStatusPage.vue')
const postboxRoute = () => import('../components/routes/postbox/CsoPostboxRoutePage.vue')
const loadProfileRoute = () => import('../components/routes/load-profile/CsoLoadProfileRouteStatusPage.vue')
const dynamicTariffsRoute = () => import('../components/routes/dynamic-tariffs/CsoDynamicTariffsRouteStatusPage.vue')
const loginRoute = () => import('../components/routes/login/CsoLoginRoute.vue')
const installmentPlanRoute = () => import('../components/routes/installment-plan/CsoInstallmentPlanRouteStatusPage.vue')
const authorizedPersonRoute = () => import('../components/routes/authorized-person/CsoAuthorizedPersonProcess.vue')
const moveRoute = () => import('../components/routes/move/CsoMoveRouteStatusPage.vue')
const contactForm = () => import('../components/routes/contact-form/CsoContactFormRouteStatusPage.vue')
const logoutRoute = () => import('../components/routes/logout/CsoLogoutRoute.vue')
const passwordForgottenRoute = () => import('../components/routes/password-forgotten/CsoPasswordForgottenRouteStatusPage.vue')
const changeInvoiceAddressRoute = () => import('../components/routes/change-invoice-address/CsoChangeInvoiceAddressRouteStatusPage.vue')
const moveInOpenRoute = () => import('../components/routes/move-in/CsoMoveInOpenRouteStatusPage.vue')
const moveInClosedRoute = () => import('../components/routes/move-in/CsoMoveInClosedRouteStatusPage.vue')
const activationRoute = () => import('../components/routes/activation/CsoActivationRouteStatusPage.vue')
const signUpRoute = () => import('../components/routes/sign-up/CsoSignUpRouteStatusPage.vue')
const addBusinessPartner = () => import('../components/routes/business-partner/CsoBusinessPartnerPage.vue')
const tariffChangeRoute = () => import('../components/routes/tariff-change/CsoTariffChangeRouteStatusPage.vue')
const deregisterRoute = () => import('../components/routes/deregister/CsoDeregisterPage.vue')
const deregisterSentPage = () => import('../components/routes/deregister/CsoDeregisterSentRoute.vue')
const genericPageRoute = () => import('../components/routes/generic-page/CsoGenericPageRoute.vue')
const digitalInterfaceDashboard = () => import('../components/routes/digital-interface-dashboard/CsoDiDashboardRoute.vue')
const cso404Route = () => import('../components/routes/404/Cso404Route.vue')

export default class CsoRouter {
  static router

  static isOpenArea

  static di

  static routes = [
    {
      path: '/404',
      name: '404',
      components: {
        default: cso404Route,
        openarea: cso404Route
      }
    },
    {
      path: '/registrierung',
      name: 'signUp',
      meta: {
        seoKey: 'REGISTRIERUNG',
        loginForbidden: true,
        robotsFollow: true
      },
      components: {
        openarea: signUpRoute
      }
    },
    {
      path: '/aktivieren',
      name: 'activation',
      meta: {
        seoKey: 'AKTIVIERUNG',
        loginForbidden: true
      },
      components: {
        openarea: activationRoute
      }
    },
    {
      path: '/kontostandinformationen',
      name: 'accountOverview',
      meta: {
        seoKey: 'KONTOSTAND',
        loginRequired: true
      },
      components: {
        default: accountOverviewRoute
      }
    },
    {
      path: '/verbrauch',
      name: 'consumptionOverview',
      meta: {
        seoKey: 'VERBRAUCH',
        loginRequired: true
      },
      components: {
        default: consumptionOverviewRoute
      }
    },
    {
      path: '/benutzerprofil',
      name: 'personalData',
      meta: {
        seoKey: 'PERSOENLICHE_DATEN',
        loginRequired: true
      },
      components: {
        default: personalDataRoute
      }
    },
    {
      path: '/freundewerben',
      name: 'tellAFriend',
      meta: {
        seoKey: 'FREUNDE_WERBEN',
        loginRequired: true
      },
      components: {
        default: tellAFriendRoute
      }
    },
    {
      path: '/vertragskonto',
      name: 'dashboard',
      meta: {
        seoKey: 'DASHBOARD',
        loginRequired: true
      },
      components: {
        default: digitalInterfaceDashboard
      }
    },
    {
      path: '/lieferstelle',
      name: 'deliveryAndContract',
      meta: {
        seoKey: 'LIEFERSTELLE',
        loginRequired: true
      },
      components: {
        default: contractAndDeliveryRoute
      }
    },
    {
      path: '/kommunikation',
      name: 'communication',
      meta: {
        seoKey: 'ONLINE_KOMMUNIKATION',
        loginRequired: true
      },
      components: {
        default: communicationRoute
      }
    },
    {
      path: '/tarifdetails',
      name: 'tariffDetails',
      meta: {
        seoKey: 'TARIFDETAILS',
        loginRequired: true
      },
      components: {
        default: tariffDetailsRoute
      }
    },
    {
      path: '/produktwechsel',
      name: 'tariffChange',
      meta: {
        seoKey: 'PRODUKTWECHSEL',
        loginRequired: true
      },
      components: {
        default: tariffChangeRoute
      }
    },
    {
      path: '/werbeeinverstaendnis',
      name: 'optin process',
      meta: {
        seoKey: 'OPT',
        loginRequired: true
      },
      components: {
        default: optinProcessRoute
      }
    },
    {
      path: '/zaehler',
      name: 'meter reading closed process',
      meta: {
        seoKey: 'ZAEHLERSTAND',
        loginRequired: true
      },
      components: {
        default: meterReadingClosedRoute
      }
    },
    {
      path: '/bankdaten',
      name: 'bank data',
      meta: {
        seoKey: 'EZE',
        loginRequired: true
      },
      components: {
        default: bankDataRoute
      }
    },
    {
      path: '/abschlag',
      name: 'part payment',
      meta: {
        seoKey: 'ABSCHLAG',
        loginRequired: true
      },
      components: {
        default: partPaymentRoute
      }
    },
    {
      path: '/postfach',
      name: 'postbox',
      meta: {
        seoKey: 'POSTFACH',
        loginRequired: true
      },
      components: {
        default: postboxRoute
      }
    },
    {
      path: '/login/:deepLink?',
      name: 'login',
      meta: {
        seoKey: 'LOGIN',
        loginForbidden: true,
        robotsFollow: true
      },
      components: {
        openarea: loginRoute
      }
    },
    {
      path: '/lastgang',
      name: 'load profile',
      meta: {
        seoKey: 'LASTGANG',
        loginRequired: true
      },
      components: {
        default: loadProfileRoute
      }
    },
    {
      path: '/dynamischertarif',
      name: 'dynamic tariffs',
      meta: {
        seoKey: 'LASTGANG',
        loginRequired: true
      },
      components: {
        default: dynamicTariffsRoute
      }
    },
    {
      path: '/ratenplan',
      name: 'installment plan',
      meta: {
        seoKey: 'RATENPLAN',
        loginRequired: true
      },
      components: {
        default: installmentPlanRoute
      }
    },
    {
      path: '/vollmacht',
      name: 'authorized person',
      meta: {
        seoKey: 'VOLLMACHT',
        loginRequired: true
      },
      components: {
        default: authorizedPersonRoute
      }
    },
    {
      path: '/umzug',
      name: 'move',
      meta: {
        seoKey: 'UMZUG',
        loginRequired: true
      },
      components: {
        default: moveRoute
      }
    },
    {
      path: '/kontakt',
      name: 'contact-form',
      meta: {
        seoKey: 'KONTAKT'
      },
      components: {
        default: contactForm,
        openarea: contactForm
      }
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        seoKey: ''
      },
      components: {
        default: logoutRoute,
        openarea: logoutRoute
      }
    },
    {
      path: '/neuespasswort',
      name: 'password forgotten',
      meta: {
        seoKey: 'PASSWORT_VERGESSEN',
        loginForbidden: true,
        robotsFollow: true
      },
      components: {
        openarea: passwordForgottenRoute
      }
    },
    {
      path: '/rechnungsadresseaendern',
      name: 'change invoice address',
      meta: {
        seoKey: 'RECHNUNGSADRESSEAENDERN',
        loginRequired: true
      },
      components: {
        default: changeInvoiceAddressRoute
      }
    },
    {
      path: '/bestellung',
      name: 'move in open',
      meta: {
        seoKey: 'EINZUG',
        simpleHeaderAndFooter: true
      },
      components: {
        openarea: moveInOpenRoute
      }
    },
    {
      path: '/einzug',
      name: 'move in closed',
      meta: {
        seoKey: 'EINZUG',
        loginRequired: true
      },
      components: {
        default: moveInClosedRoute
      }
    },
    {
      path: '/weiteresvertragskonto',
      name: 'addBusinessPartner',
      meta: {
        seoKey: 'VERTRAG_ZUORDNEN',
        loginRequired: true
      },
      components: {
        default: addBusinessPartner
      }
    },
    {
      path: '/deregistrierung',
      name: 'deregister',
      meta: {
        seoKey: 'DEREGISTRIERUNG'
      },
      components: {
        openarea: deregisterSentPage,
        default: deregisterRoute
      }
    },
    {
      path: '/online',
      name: 'genericPage',
      meta: {
        seoKey: '',
        simpleHeaderAndFooter: true,
        loginForbidden: true
      },
      components: {
        openarea: genericPageRoute
      }
    },
    {
      path: '/kuendigen|impressum',
      name: 'vfde',
      beforeEnter: (to) => {
        location.href = `//vattenfall.de/${to.path}`
        return false
      }
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/service',
      redirect: '/login'
    },
    {
      path: '/:all(.*)',
      redirect: '/404'
    }
  ]

  static setRouterObject (router) {
    this.router = router
  }

  static setDiObject (di) {
    this.di = di
  }

  static parseQueryString (url) {
    let parts = url.split('?')
    let query = {}
    if (parts[1]) {
      let params = parts[1].split('&')
      for (const param of params) {
        let paramParts = param.split('=')
        query[paramParts[0]] = paramParts[1]
      }
    }
    return { base: parts[0], query }
  }

  static findInRoutes (url) {
    if (!url || url == '/') {
      return false
    }
    let urlData = this.parseQueryString(url)
    let foundRoute = this.routes.find(needle => {
      let path = needle.path.split('?')[0].replace('/:deepLink', '')
      return path === urlData.base
    })
    if (!foundRoute) {
      return false
    }
    if (urlData.query) {
      foundRoute.query = urlData.query
    }
    return foundRoute
  }

  static redirectToSessionLostLoginPage () {
    this.isOpenArea = true
    this.router.push({ name: 'login', query: { sessionLost: 'true' } })
  }

  static checkIfLoginRequired (to) {
    const isAnonymous = !this.di.token || this.di.token.isAnonymous()
    if (to.meta.loginRequired && isAnonymous) {
      this.isOpenArea = true
      const deepLink = to.path.slice(1)
      return { name: 'login', params: { deepLink } }
    }
  }

  static checkIfLoginForbidden (to) {
    const isAnonymous = !this.di.token || this.di.token.isAnonymous()
    if (to.meta.loginForbidden && !isAnonymous) {
      this.isOpenArea = false
      return { name: 'dashboard' }
    }
  }

  static async checkIfLoginTemporary () {
    const tmp = this.di.token?.isBusinessPartner() || this.di.token?.isContractAccount()
    if (tmp) {
      await this.di.v13.deauthenticate()
      await this.di.v13.authenticateAnonymous()
    }
  }
}

export {
  accountOverviewRoute,
  consumptionOverviewRoute,
  personalDataRoute,
  tellAFriendRoute,
  contractAndDeliveryRoute,
  communicationRoute,
  tariffDetailsRoute,
  optinProcessRoute,
  meterReadingClosedRoute,
  bankDataRoute,
  partPaymentRoute,
  postboxRoute,
  loadProfileRoute,
  dynamicTariffsRoute,
  loginRoute,
  installmentPlanRoute,
  authorizedPersonRoute,
  moveRoute,
  contactForm,
  logoutRoute,
  passwordForgottenRoute,
  changeInvoiceAddressRoute,
  moveInOpenRoute,
  moveInClosedRoute,
  activationRoute,
  signUpRoute,
  addBusinessPartner,
  tariffChangeRoute,
  deregisterRoute,
  deregisterSentPage,
  genericPageRoute,
  digitalInterfaceDashboard,
  cso404Route
}
