<template>
  <div class="cso-user-popup" v-if="$route.query.userPopup">
    <div class="content">
      <span v-html="$t('userPopup.text1')"></span>
      <br><br>
      <span v-html="$t('userPopup.text2')"></span>
      <br><br>
      <span v-html="$t('userPopup.text3').split('hier')[0]"></span>
      <span class="link" @click="go">hier</span>
      <span v-html="$t('userPopup.text3').split('hier')[1]"></span>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CsoUserPopup',

  methods: {
    go () {
      this.$router.push({ name: 'personalData' })
    }
  }
}
</script>

<style lang="scss">
.cso-user-popup {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: #000000b8;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .content {
    background-color: white;
    width: 90%;
    border-radius: 4px;
    padding: 40px 20px;
    max-width: 640px;
    margin: 0 20px 0 0;
    font-size: 16px;
    
    @include xs {
      margin: 50px 20px 0 0;
      padding: 20px 10px;
      font-size: 14px;
    }
  }

  .link {
    color: #2071B5;
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
