import * as Model from '../../core/1.3/model'
import { type Dokument, type Medium, type Parameter, type Referenzen, type Text } from './types'

// Alias for convenience.
type Response = Model.Produkt.GetProdukte.Response
export type ResponseProdukteFelder = {
  JAHRESVERBRAUCH: string
}
class ProduktResponse extends Model.Produkt.GetProdukte.ResponseProdukte {}

/**
 * Product helper.
 */
export default class Produkt extends ProduktResponse {
  /**
   * Creates products from response.
   */
  public static create (response: Response) {
    const referenzen = response.Referenzen || {}
    const produkte = response.Produkte.map(produkt => new Produkt(produkt, referenzen))
    return { produkte, referenzen }
  }

  /**
   * Product references.
   */
  public Referenzen: Referenzen

  /**
   * Instantiates a new product helper.
   */
  public constructor (product: ProduktResponse, referenzen: Referenzen) {
    super()
    Object.assign(this, product)
    this.Referenzen = referenzen
  }

  /**
   * Whether product is commodity.
   */
  public get Comm () {
    return this.Sparte.Code !== 'NONCOMM'
  }

  /**
   * Whether product is non-commodity.
   */
  public get Noncomm () {
    return this.Sparte.Code === 'NONCOMM'
  }
  /**
   * Whether product is designated for private customers.
   */
  public get Privat () {
    return this.Kundenart.Code === 'PRIVAT'
  }

  /**
   * Whether product is designated for company customers.
   */
  public get Gewerbe () {
    return this.Kundenart.Code === 'GEWERBE'
  }

  /**
   * Annual cost.
   */
  public get KostErstjahr () {
    return this.getKost('ERSTJAHR')
  }

  /**
   * Annual cost for customer.
   */
  public get KostErstjahrFurKunde () {
    return this.Privat ? this.KostErstjahr!.Jahreskosten.Brutto : this.KostErstjahr!.Jahreskosten.Netto
  }

  /**
   * Active price.
   */
  public get RelevantePreis () {
    return this.Preiszeitscheiben
      .find(preis => preis.RelevanteZeitscheibe)!
      .Preisstaffel
      .find(staffel => staffel.RelevanteStaffel)!
  }

  /**
   * Active price per month for customer.
   */
  public get RelevantePreisPerMonatFurKunde () {
    const preis = this.RelevantePreis.GrundpreisMonat
    return this.Gewerbe ? preis.Netto : preis.Brutto
  }

  /**
   * Active bonus.
   */
  public get RelevanteBonus () {
    return this.Bonussteuerung.Bonuswerte[0].Staffel.find(staffel => staffel.RelevanteStaffel)!
  }

  /**
   * Up-sell information.
   */
  public get Upsell () {
    const code = this.getParameter('PRODUKT', 'UPSELL')
    const beschreibung = this.getText('DI_PRODUKT', 'TEXT_HINWEIS_UPSELL')
    return {
      Code: (code && code.Name) || '',
      Beschreibung: (beschreibung && beschreibung.Langtext) || ''
    }
  }

  /**
   * Cross-sell information.
   */
  public get Crosssell () {
    const code = this.getParameter('PRODUKT', 'CROSSSELL')
    const beschreibung = this.getText('DI_PRODUKT', 'TEXT_HINWEIS_CROSSSELL')
    return {
      Code: (code && code.Name) || '',
      Beschreibung: (beschreibung && beschreibung.Langtext) || ''
    }
  }

  /**
   * Returns footnote with given name.
   */
  public getFootnote (name: string) {
    return this.getText('FUSSNOTE', name)
  }

  /**
   * Returns cost for given period.
   */
  public getKost (zeitraum: string) {
    return this.Kosten.find(kost => kost.Zeitraum === zeitraum)
  }

  /**
   * Returns dokument.
   */
  public getDokument (hauptkategorie?: string, unterkategorie?: string) {
    return this.getDokumente(hauptkategorie, unterkategorie)[0]
  }

  /**
   * Returns documents.
   */
  public getDokumente (hauptkategorie?: string, unterkategorie?: string) {
    const ids = this.DokumenteIds.concat(this.BonusDokumenteIds)
    const dokumente = this.Referenzen.Dokumente.filter(dokument =>
      ids.includes(dokument.ReferenzID) &&
      (!hauptkategorie || dokument.Dokument.Hauptkategorie === hauptkategorie) &&
      (!unterkategorie || dokument.Dokument.Unterkategorie === unterkategorie)
    )
    return dokumente.map(dokument => ({
      ...dokument.Dokument,
      ReferenzID: dokument.ReferenzID
    }) as Dokument)
  }

  /**
   * Returns media identified by category and subcategory.
   */
  public getMedium (hauptkategorie: string, unterkategorie?: string) {
    const relatedIds = this.BonusMedienIds.length ? this.BonusMedienIds : this.MedienIds
    const medium = this.Referenzen.Medien.find(medium =>
      medium.Medium.Hauptkategorie === hauptkategorie &&
      medium.Medium.Unterkategorie === (unterkategorie || null) &&
      relatedIds.includes(medium.ReferenzID)
    )
    return medium && {
      ...medium.Medium,
      ReferenzID: medium.ReferenzID
    } as Medium
  }

  /**
   * Returns parameter identified by category and subcategory.
   */
  public getParameter (hauptkategorie: string, unterkategorie?: string) {
    const parameter = this.Referenzen.Parameter.find(parameter =>
      parameter.Parameter.Hauptkategorie === hauptkategorie &&
      parameter.Parameter.Unterkategorie === (unterkategorie || null) &&
      this.ParameterIds.includes(parameter.ReferenzID)
    )
    return parameter && {
      ...parameter.Parameter,
      ReferenzID: parameter.ReferenzID
    } as Parameter
  }

  /**
   * Returns text identified by category and subcategory.
   */
  public getText (hauptkategorie: string, unterkategorie?: string) {
    return this.getTexte(hauptkategorie, unterkategorie)[0]
  }

  /**
   * Returns texts identified by category and subcategory.
   */
  public getTexte (hauptkategorie: string, unterkategorie?: string) {
    const isBonus = hauptkategorie === 'BONUS' || hauptkategorie === 'DI_BONUS'
    const relatedIds = isBonus ? this.BonusTexteIds : this.TexteIds
    const texte = this.Referenzen.Texte.filter(text =>
      text.Text.Hauptkategorie === hauptkategorie &&
      text.Text.Unterkategorie === (unterkategorie || null) &&
      relatedIds.includes(text.ReferenzID)
    )
    return texte.map(text => ({
      ...text.Text,
      ReferenzID: text.ReferenzID
    }) as Text)
  }

  public getOptsTexte () {
    const unterkategorie = 'CB_EINVERSTAENDNIS_OPT_'
    const texts = this.Referenzen.Texte.reduce((filtered: any[], text) => {
      if (text.Text.Hauptkategorie === 'DI_OPT' &&
          (text.Text.Unterkategorie.slice(0, -1)) === unterkategorie &&
          this.TexteIds.includes(text.ReferenzID)) {
        filtered.push(text.Text)
      }
      return filtered
    }, [])
    return texts
  }

  /**
   * Safely reads document ids.
   */
  public get DokumenteIds () {
    return this.Inhalte.DokumenteReferenzIds || []
  }

  /**
   * Safely reads media ids.
   */
  public get MedienIds () {
    return this.Inhalte.MedienReferenzIds || []
  }

  /**
   * Safely reads text ids.
   */
  public get TexteIds () {
    return this.Inhalte.TextReferenzIds || []
  }

  /**
   * Safely reads parameter ids.
   */
  public get ParameterIds () {
    return this.Inhalte.ParameterReferenzIds || []
  }

  /**
   * Safely reads bonus text ids.
   */
  public get BonusTexteIds () {
    return (this.Bonussteuerung && this.Bonussteuerung.Inhalte && this.Bonussteuerung.Inhalte.TextReferenzIds) || []
  }

  /**
   * Safely reads bonus media ids.
   */
  public get BonusMedienIds () {
    return (this.Bonussteuerung && this.Bonussteuerung.Inhalte && this.Bonussteuerung.Inhalte.MedienReferenzIds) || []
  }

  /**
   * Safely reads bonus document ids.
   */
  public get BonusDokumenteIds () {
    return (this.Bonussteuerung && this.Bonussteuerung.Inhalte && this.Bonussteuerung.Inhalte.DokumenteReferenzIds) || []
  }
}
