<template>
  <cso-component-state :componentState="state" v-if="contracts.length > 0">
    <template v-slot:ready>
      <div class="cso-contract-selector-wrapper"
           :class="{'disabled': disableContractSelector}">
          <span class="selected__contract--label">
            {{ $t('contractSelection.labels.selectContract') }}
          </span>
        <div class="selector-wrapper"
             @click="open()">
          <div class="pull-start">
          <div class="pull-start--icon"
            v-if="selectedContract"
            :class="getIcon(selectedContract)">
           </div>
          <div class="selected__contract">
              <span>{{ selectedContract.type }}</span> {{ selectedContract.street }} {{ selectedContract.building }}
          </div>
        </div>
        <div class="pull-end">
          <span v-if="selectedContract.status">
            ({{ selectedContract.status }})
          </span>
          <div class="circle-icon"
              v-if="!disableContractSelector">
            <div class="circle">
            </div>
            <div class="pull-end--icon down"></div>
            </div>
          </div>
        </div>
      </div>
      <cso-dialog :header="$t('contractSelection.headers.contractSelectionHeader')"
                  class="custom-modal-view"
                  ref="dialog">
        <nav class="contract-selector-dialog center-side"
             :class="{'cso-fetching': selecting}">
          <div class="table-header">
            <span>{{ $t('contractSelection.headers.product') }}</span>
            <span>{{ $t('contractSelection.headers.address') }}</span>
            <span>{{ $t('contractSelection.headers.status') }}</span>
            <span>{{ $t('contractSelection.headers.accountNumber') }}</span>
          </div>
          <ul>
            <li v-for="(contract, index) in dialogContracts"
                @click="select(contract)"
                :class="{'active': contract.number === selectedContract.number}"
                :key="index">
              <div class="contract">
                <div>
                  <div class="dialog-contract">
                    <div class="dialog-contract--icon"
                         :class="getIcon(contract)">
                    </div>
                    <span class="dialog-contract--span">{{ contract.type }}</span>
                  </div>
                  <span class="address"> {{ contract.street }} {{ contract.building }}<span v-if="contract.postcode || contract.city">,</span> {{ contract.postcode }} {{ contract.city }}</span>
                </div>
                <div class="table-end">
                  <div class="table-end--status">
                    <span v-if="contract.status">({{ contract.status }})</span>
                    <span class="table-end--contract-mobile">{{ $t('contractSelection.headers.contractNumber') }}</span>
                  </div>
                  <div>
                    <span>{{ contract.value }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="selection-footer">
            <div @click="addGP()" class="selection-footer--btn">
              {{ $t('contractSelection.labels.addAccount') }}
            </div>
            <div @click="addGP()" class="selection-footer--btn-mobile">
              {{ $t('contractSelection.labels.addAccount') }}
            </div>

            <div class="selection-footer--pagination">
              <paginate v-if="!selecting"
                        v-model="page"
                        :click-handler="setPage"
                        :initial-page="page"
                        :page-count="pages"
                        :active-class="'pagination-active'"
                        :page-link-class="'pagination-link'"
                        :container-class="'pagination-list'"
                        :next-class="'hidden'"
                        :prev-class="'hidden'"
                        :key="pages">
              </paginate>
            </div>
          </div>
        </nav>
        <div v-if="error"
             class="show-error">
          {{ error }}
        </div>
      </cso-dialog>
    </template>
  </cso-component-state>
</template>

<script lang="ts">
import { Subscription } from 'rxjs'
import Paginate from 'vuejs-paginate-next'

import store, { type State } from '@/store'
import * as DI from '@/api/digital-interface/client'
import ComponentsStates from '@/common/ComponentsStates'
import LoggingService from '@/common/LoggingService'

import CsoComponentState from '@/components/core/composition/CsoComponentState.vue'
import CsoDialog from '@/components/core/composition/CsoDialog.vue'

let subscription: Subscription

interface Contract {
  number: string
  code: 'STROM' | 'GAS' | 'NONCOMM'
  type: string
  street: string
  building: string
  postcode: string
  city: string
  value: string
  status: string
}

interface Dialog {
  open (): void
  close (): void
}

interface Options {
  hasBusinessPartner: boolean
  hasContract: boolean
}

export default {
  name: 'CsoContractSelector',

  components: {
    Paginate,
    CsoDialog,
    CsoComponentState
  },

  props: {
    disableContractSelector: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      state: ComponentsStates.unavailable,
      page: 1,
      contractsOnPage: 4,
      contracts: [] as Contract[],
      selectedContractNumber: '',
      selecting: false,
      error: '',
      vk: ''
    }
  },

  computed: {
    pages (): number {
      return Math.ceil(this.contracts.length / this.contractsOnPage)
    },
    dialogContracts (): Contract[] {
      const offset = (this.page - 1) * this.contractsOnPage
      return this.contracts.slice(offset, offset + this.contractsOnPage)
    },
    selectedContract (): Contract {
      return this.contracts.find(contract => contract.number === this.selectedContractNumber)! || {}
    }
  },

  async created () {
    subscription = store.subscribe(this.observeStore)
    await this.fetch()
  },

  unmounted () {
    subscription.unsubscribe()
  },

  methods: {
    observeStore (state: State) {
      this.setContracts(state.allVk)
      this.setSelectedContract(state.vk)
      if (state.vk !== this.vk) {
        this.vk = state.vk
        this.setContracts(state.allVk)
        this.setSelectedContract(state.vk)
      }
    },
    async fetch () {
      this.state = ComponentsStates.silentLoading
      this.error = ''
      try {
        await store.fetchVertragsliste()
        const hasBusinessPartner = store.hasGP
        const hasContract = store.hasVK
        this.$emit('loadedContracts', { hasContract, hasBusinessPartner } as Options)
      } catch (error) {
        this.handleError(error)
        this.$emit('failedLoadingContracts')
      }
      this.state = ComponentsStates.ready
    },

    async reload () {
      store.clearVertragsliste()
      await this.fetch()
    },

    async select (contract: Contract) {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Selected contract', contract)
      this.selecting = true
      this.error = ''
      try {
        await store.selectVK(contract.number)
      } catch (error) {
        this.handleError(error)
      }
      this.selecting = false
      this.close()
      if (this.$route.name !== 'dashboard') { // TODO: we could actually remove it in the future and let the processes handle it themselves
        this.$emit('rerender')
      }
    },

    setContracts (allVk: Array<DI.Model13.Vertrag.GetVertragsliste.ResponseGPVertraege>) {
      this.contracts = allVk.map((VK) => ({
        number: VK.VK.ReferenzID,
        code: VK.Sparte.Code as Contract['code'],
        type: VK.Sparte.Anzeigetext,
        street: VK.Lieferstelle?.Adresse?.Strasse || '',
        building: VK.Lieferstelle?.Adresse?.Hausnummer || '',
        postcode: VK.Lieferstelle?.Adresse?.PLZ || '',
        city: VK.Lieferstelle?.Adresse?.Ort || '',
        value: VK.VK.Anzeigetext,
        status: this.getContractStatus(VK.Vertragsstatus.Anzeigetext)
      }))
    },

    setSelectedContract (vk: string) {
      this.selectedContractNumber = vk
    },

    open () {
      if (this.disableContractSelector || this.contracts.length === 0) {
        return
      }
      const dialog = this.$refs.dialog as Dialog
      dialog.open()
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Contract selector open')
    },

    close () {
      const dialog = this.$refs.dialog as Dialog
      dialog.close()
      LoggingService.log(LoggingService.eventTypes.userEvent, 'Contract selector closed')
    },

    getIcon (contract: Contract) {
      return {
        'electricity': contract.code === 'STROM',
        'gas': contract.code === 'GAS',
        'non-comm': contract.code === 'NONCOMM'
      }
    },

    addGP () {
      LoggingService.log(LoggingService.eventTypes.userEvent, 'addGP button clicked')
      this.close()
      this.$router.push({ name: 'addBusinessPartner' })
    },

    setPage (page: number) {
      this.page = page
    },

    handleError (error: unknown) {
      LoggingService.error(LoggingService.eventTypes.userEvent, 'Error: ', error)
      if (error instanceof DI.ErrorResponse) {
        this.error = error.text
      } else {
        this.error = this.$t('general.errors.post') as string
      }
    },

    getContractStatus (contract: string) {
      return contract.replace(/(Bestellung )|(Vertrag )/, '')
    }
  }
}
</script>

<style lang="scss">
.show-error {
  text-align: center;
  padding-bottom: 10px;
}

.custom-modal-view .modal-window__header {
  font-size: $font-family-regular;
  border-bottom: none;
  padding: 20px;
}

.electricity {
  background-image: url('/static/icons/electricity.svg');
}

.gas {
  background-image: url('/static/icons/gas.svg');
}

.non-comm {
  background-image: url('/static/icons/noncomm.svg');
}

.cso-contract-selector-wrapper {
  @include hidden-print();
  position: relative;
  font-family: $font-family-regular;
  height: 40px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  top: 0;
  z-index: 2;

  @include lg {
    margin: 0;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }

  @include sm {
    margin: 0;
    height: auto;
    top: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  &.disabled {
    cursor: auto;

    @include sm {
      padding-bottom: 10px;
    }
  }

  .selected__contract--label {
    width: max-content;

    @include md {
      width: min-content;
      display: inline-block;
    }

    @include sm {
      width: max-content;
    }

    @include sm {
      display: none;
    }
  }

  .selected__contract {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;

    @include lg {
      width: 180px;
    }

    span {
      font-family: $font-family-bold;
      margin-right: 25px;

      @include sm {
        display: block;
        margin: auto;
      }
    }
  }

  @include sm {
    margin: auto;
  }

  .pull-start {
    display: flex;
    flex-wrap: nowrap;
    width: 65%;
    text-overflow: ellipsis;

      @include lg {
        width: 55%;
      }

    .pull-start--icon {
      flex: 0 0 24px;
      height: 24px;
      margin-right: 12px;
      background-repeat: no-repeat;
    }

    .electricity {
      background-image: url('/static/icons/electricity.svg');
    }

    .gas {
      background-image: url('/static/icons/gas.svg');
    }

    .non-comm {
      background-image: url('/static/icons/noncomm.svg');
    }

    div {
      align-self: center;
      color: $black;
    }

    span {
      align-self: center;
      color: $black;
      width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;

      @include lg {
        width: 170px;
      }

      @include sm {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        width: 195px;
      }

    }
  }

  .pull-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 2;
    font-size: 14px;

    @include lg {
      width: 25%;
    }

    @include xs {
      line-height: 20px;
      min-height: 28px;
      width: auto;
    }

    .circle-icon {
      position: relative;
      width: 36px;
      height: 36px;
      margin-left: 13px;

      @include sm {
        margin-left: 5px;
      }
    }

    .circle {
      position: absolute;
      border-radius: 60px;
      background: $white;
      width: 36px;
      height: 36px;
      top: 0;
      left: 0;
    }

    .pull-end--icon.down {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 17%;
      left: 17%;
      background-image: url('/static/icons/down.svg');
    }

    span {
      align-self: center;
      color: $black;
      width: max-content;
      padding-right: 11px;

      @include xs {
        padding-right: 0;
      }
    }
  }

  .selector-wrapper {
    background-color: $yellow;
    border-radius: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    height: 52px;
    padding: 18px 8px 18px 24px;
    width: 600px;
    cursor: pointer;

    &:hover {
      background-color: $yellow-pale;
    }

    @include lg {
      width: 463px;
    }

    @include sm {
      height: 62px;
      flex-wrap: nowrap;
      padding: 15px 24px;
      margin: 0 10px;
      width: 100vw;
    }
  }

  &.disabled .selector-wrapper,
  &.disabled .selector-wrapper:hover {
    background-color: $gray-e6;
    cursor: auto;
  }
}

.center-side {
  flex-direction: column;
  justify-content: center;
  transition: width 0.5s ease-in-out;

  .table-header {
    padding: 0 32px;
    font-size: 14px;
    color: $gray-dark;
    display: grid;
    grid-template-columns: 140px 505px 77px 127px;
    justify-content: flex-start;
    text-align: start;

    @include md {
      display: none;
    }
  }

    ul {
    list-style: none;
    padding: 0;

    @include md {
      display: flex;
      flex-direction: column;
    }

    li {
      background-color: $background-gray-lighter;
      display: flex;
      padding: 24px 32px;
      margin-bottom: 4px;

      @include md {
        width: auto;
        height: 176px;
        padding: 16px;
        margin: 2px 16px;
        gap: 0;
        align-items: center;
      }

      @include xs {
        margin: 2px auto;
        max-width: 94vw;
        overflow: hidden;
        width: 100%;
      }

      &:hover {
        @media (pointer: fine) {
          background-color: $gray-light;
        }
      }

      div.contract {
        cursor: pointer;
        line-height: 22px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: $black;
        transition: background 0.5s ease-in-out, color 0.5s ease;

        @include md {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }

        div {
          display: grid;
          grid-template-columns: 1fr 3fr;

          @include md {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            max-width: 100%;
          }
        }

        .dialog-contract {

          @include md {
            display: flex;
            flex-direction: row;
            margin-bottom: 8px;
          }
        }

        .dialog-contract--span {
          font-family: $font-family-bold;
        }

        .dialog-contract--icon {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          margin-right: 8px;

            @include md {
              height: 20px;
              width: 20px;
            }
        }

        span.address {
          width: 435px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include lg {
            width: 410px;
          }

          @include md {
            max-width: 100%;
          }
        }

        .table-end {
          display: grid;
          width: 33%;
          grid-template-columns: 1fr 1fr;

          @include md {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            span:nth-child(1) {
              margin-bottom: 8px;
            }
          }

          .table-end--contract-mobile {
            display: none;

            @include md {
              display: block;
            }
          }

          .table-end--status {
            width: 100%;
            display: flex;
            justify-content: end;
            padding-right: 21px;
          }
        }

        span {
          align-self: center;
          font-size: 16px;
          font-family: $font-family-regular;
          font-weight: 400;
          width: max-content;

          @include md {
            align-self: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: auto;
            max-width: 100%;
          }

          b {
            font-weight: 700;
            margin-right: 16px;

            @include md {
              display: block;
            }
          }
        }
      }
    }

    li.active {
      background-color: $yellow;
    }
  }
}
.selection-footer {
  font-family: $font-family-regular;
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  height: 54px;
  width: 100%;

  @include md {
    justify-content: flex-start;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 12px;
    height: auto;
    margin: auto;
    width: 400px;
    max-width: 90vw;
  }

  &--btn {
    width: 202px;
    cursor: pointer;
    color: $blue;
    font-size: 16px;
    white-space: nowrap;

    @include md {
      display: none;
    }
  }
  &--btn-mobile {
    display: none;

    @include md {
      display: block;
      cursor: pointer;
      color: $blue;
      font-size: 16px;
    }
  }

  &--pagination {
    display: flex;
    justify-content: center;
    max-width: 94vw;

    @include md {
      height: 63px;
    }
  }

  ul.pagination-list {
    display: inline-flex;
    margin-top: 2px;
    max-width: 100%;

    @include md {
      display: flex;
      flex-direction: row;
    }

    li {
      font-family: $font-family-regular;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      border-radius: 40px;
      border: 1px solid #000;
      background-color: $white;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      margin-bottom: 0;
      margin-right: 5px;
      width: 40px;
      height: 40px;

      @include md {
        margin: 0 6px;
      }

      &:hover {
        @media (pointer: fine) {
          border: 2px solid $yellow;
          background-color: $yellow;
          color: $black;
        }
     }

      a.pagination-link {
        color: $black;
        justify-content: center;
        display: block;
        outline: none;
        padding: 9px 20px 8px 20px;

        &:hover {
          color: $blue-night;
        }
      }
    }

    li.pagination-active {
      border: 2px solid $yellow;
      background-color: $yellow;
      color: $blue-night;
    }
  }
}
</style>
