<template>
  <cso-component-state :componentState="state">
    <template v-slot:ready>
      <section class="cso-faq" 
               @click="toggleItem" 
               v-html="faq">
      </section>
    </template>
  </cso-component-state>
</template>

<script lang="ts">
import di from '@/api/digital-interface'
import * as DI from '@/api/digital-interface/client'

import Utils from '@/common/Utils'
import ComponentsStates from '@/common/ComponentsStates'
import CsoComponentState from '@/components/core/composition/CsoComponentState.vue'

export default {
  name: 'CsoFaq',
  components: {
    CsoComponentState
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      state: ComponentsStates.loading,
      faq: ''
    }
  },
  methods: {
    toggleItem (event: Event) {
      const target = event.target as HTMLElement
      if (target.tagName == 'H2') {
        target.classList.toggle('opened')
      }
    }
  },
  async created () {
    try {
      const inhalt = await di.v13.getInhalt()
      const faq = inhalt.getContent(this.name)?.Properties[0]?.Wert || ''
      const references = { Referenzen: DI.V13.emptyReferenzen() }
      this.faq = await Utils.replaceMarkdown(faq, references, di)
      this.state = ComponentsStates.ready
    } catch {
      this.state = ComponentsStates.simpleError
    }
  }
}
</script>

<style lang="scss">
.cso-faq {
  @include hidden-print();
  margin: auto;
  padding: 66px 0 42px 0;
  max-width: 1062px;

  h1 {
    font-size: 36px;
    font-family: $font-family-bold;
    margin: 0 0 20px 0;
    text-align: center;
  }

  h2 {
    padding: 20px 0 24px 0;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    font-family: $font-family-bold;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;

    &::after {
      background-image: url(/static/icons/arrow_down.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      flex: 0 0 16px;
      content: "";
    }
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    border-bottom: 1px solid #cccccc;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: all .2s ease;
  }

  @include sm {
    padding: 32px 0 26px 0;

    h1 {
      font-size: 24px;
    }
  }

  h2.opened {
    &::after {
      transform: rotate(180deg);
    }

    & + h3 {
      padding: 0 0 20px 0;
      max-height: 200px;
    }
  }
}
</style>
