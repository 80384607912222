import { type DateString } from '../model-shared'

export namespace Abschlag {
  export namespace GetAbschlaege {
    export class Request {
      public VK?: string[]
    }
    export class ResponseWerte { }
    export class ResponseAbschlagsplaeneVK {
      public AnzeigeText!: string
      public ReferenzID!: string
    }
    export class ResponseAbschlagsplaeneAbschlagsplanStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAbschlagsplaeneAbschlagsplan {
      public Abschlagsbetrag!: number
      public FaelligZum!: DateString
      public Status!: ResponseAbschlagsplaeneAbschlagsplanStatus
    }
    export class ResponseAbschlagsplaeneAbschlagsgrenzen {
      public NeuerAbschlagsbetragMax!: number
      public NeuerAbschlagsbetragMin!: number
    }
    export class ResponseAbschlagsplaene {
      public Abschlagsbetrag!: number
      public Abschlagsgrenzen!: ResponseAbschlagsplaeneAbschlagsgrenzen
      public Abschlagsplan!: ResponseAbschlagsplaeneAbschlagsplan[]
      public VK!: ResponseAbschlagsplaeneVK
    }
    export class Response {
      public Abschlagsplaene!: ResponseAbschlagsplaene[]
      public Werte!: ResponseWerte
    }
  }
  export namespace UpdateAbschlag {
    export class RequestWerte { }
    export class RequestDateiAnhaenge {
      public Dateiname?: string
      public DateiTyp?: string
      public Daten?: string
    }
    export class Request {
      public DateiAnhaenge?: RequestDateiAnhaenge[]
      public NeuerAbschlagbetrag?: number
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public NeuerAbschlagsbetragFaelligZum!: DateString
    }
  }
}

export namespace Account {
  export namespace Create2FAKommunikation {
    export class RequestWerte { }
    export class Request {
      public GP?: string
      public ID?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public GP!: string
      public GueltigkeitSekunden!: number
    }
  }
  export namespace CreateAccount {
    export class RequestWerte { }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAccountdaten { }
    export class Request {
      public Accountdaten?: RequestAccountdaten
      public Dokumente?: RequestDokumente[]
      public Emailadresse?: string
      public Gruppen?: string[]
      public Konfiguration?: string
      public Mobilnummer?: string
      public Nachname?: string
      public Passwort?: string
      public Status?: string
      public Tenant?: string
      public Username?: string
      public Vorname?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public Username!: string
    }
  }
  export namespace CreateAccountAktivierung {
    export class RequestWerte { }
    export class Request {
      public CodeEmail?: string
      public CodeMobilnummer?: string
      public PasswortNeu?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public Username!: string
    }
  }
  export namespace CreateAccountGPZuordnung {
    export class RequestWerte { }
    export class Request {
      public Username?: string
      public Werte?: RequestWerte
    }
  }
  export namespace CreateTokenUebergabe {
    export class Request {
      public ZielClient?: string
    }
    export class Response {
      public TransferId!: string
      public TransferLink!: string
    }
  }
  export namespace DeleteAccount {
    export class Request {
      public ManuelleLoeschung?: boolean
      public Tenant?: string
      public Username?: string
    }
    export class ResponseGP {
      public Anzeigetext!: string
      public ReferenzID!: string
    }
    export class Response {
      public GP!: ResponseGP[]
    }
  }
  export namespace DeleteAccountGPZuordnung {
    export class RequestWerte { }
    export class Request {
      public Username?: string
      public Werte?: RequestWerte
    }
  }
  export namespace DeleteToken {
    export class Request {
      public AccessToken?: string
      public AgentUser?: string
      public GP?: string
      public RefreshToken?: string
      public SSOID?: string
      public VK?: string
      public Zaehlernummer?: string
    }
    export class Response { }
  }
  export namespace Get2FAKommunikation {
    export class RequestWerte { }
    export class Request {
      public GP?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseKanaele {
      public Anzeigetext!: string
      public Code!: string
      public ID!: string
      public Kommunikationsadresse!: string
    }
    export class Response {
      public GP!: string
      public Kanaele!: ResponseKanaele[]
    }
  }
  export namespace GetAccount {
    export class RequestWerte { }
    export class Request {
      public AccountId?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class ResponseWerte { }
    export class ResponseStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseKampagnen {
      public Code!: string
    }
    export class ResponseGruppen {
      public Anzeigetext!: string
      public Code!: string
      public GruppeId!: string
      public Status!: string
      public Typ!: string
    }
    export class ResponseBerechtigungen {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAccountdaten { }
    export class Response {
      public Accountdaten!: ResponseAccountdaten
      public AccountId!: string
      public Berechtigungen!: ResponseBerechtigungen[]
      public Emailadresse!: string
      public Gruppen!: ResponseGruppen[]
      public Kampagnen!: ResponseKampagnen[]
      public Konfiguration!: string
      public Mobilnummer!: string
      public Nachname!: string
      public Status!: ResponseStatus
      public Tenant!: string
      public Username!: string
      public Vorname!: string
      public Werte!: ResponseWerte
    }
  }
  export namespace GetAccountliste {
    export class RequestWerte { }
    export class Request {
      public Code?: string
      public Tenant?: string
      public Typ?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class ResponseWerte { }
    export class ResponseAccountsStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAccountsAccountdaten { }
    export class ResponseAccounts {
      public Accountdaten!: ResponseAccountsAccountdaten
      public AccountId!: string
      public Emailadresse!: string
      public Mobilnummer!: string
      public Nachname!: string
      public Status!: ResponseAccountsStatus
      public Username!: string
      public Vorname!: string
    }
    export class Response {
      public Accounts!: ResponseAccounts[]
      public Code!: string
      public Typ!: string
      public Werte!: ResponseWerte
    }
  }
  export namespace GetTempPasswort {
    export class RequestWerte { }
    export class Request {
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public Username!: string
    }
  }
  export namespace GetTokenAgent {
    export class RequestWerte { }
    export class Request {
      public ADToken?: string
      public Passwort?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenAllgemein {
    export class RequestWerte { }
    export class Request {
      public Tenant?: string
      public Werte?: RequestWerte
    }
    export class ResponseWerte { }
    export class Response {
      public AccessToken!: string
      public GP!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
      public VK!: string
      public Werte!: ResponseWerte
    }
  }
  export namespace GetTokenAnonym {
    export class RequestWerte { }
    export class Request {
      public Tenant?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenBusiness {
    export class RequestWerte { }
    export class Request {
      public Passwort?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenKerberos {
    export class RequestWerte { }
    export class Request {
      public Tenant?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenRefresh {
    export class RequestWerte { }
    export class Request {
      public RefreshToken?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenSystem {
    export class RequestWerte { }
    export class Request {
      public ADToken?: string
      public Passwort?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace GetTokenUser {
    export class RequestWerte { }
    export class Request {
      public ADToken?: string
      public Passwort?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public AccessToken!: string
      public InterneZeit!: string
      public RefreshToken!: string
      public Username!: string
    }
  }
  export namespace SetPasswort {
    export class RequestWerte { }
    export class Request {
      public PasswortNeu?: string
      public Tenant?: string
      public Username?: string
      public Werte?: RequestWerte
    }
  }
  export namespace Update2FAKommunikation {
    export class RequestWerte { }
    export class Request {
      public Code?: string
      public Werte?: RequestWerte
    }
    export class Response { }
  }
  export namespace UpdateAccount {
    export class RequestWerte { }
    export class RequestGruppe {
      public GruppeEntfernen?: string[]
      public GruppeHinzufuegen?: string[]
    }
    export class RequestAccountdaten { }
    export class Request {
      public Accountdaten?: RequestAccountdaten
      public AccountId?: string
      public Emailadresse?: string
      public Gruppe?: RequestGruppe
      public Konfiguration?: string
      public Mobilnummer?: string
      public Nachname?: string
      public Status?: string
      public Tenant?: string
      public Username?: string
      public Vorname?: string
      public Werte?: RequestWerte
    }
  }
  export namespace UpdatePasswort {
    export class Request {
      public PasswortAlt?: string
      public PasswortNeu?: string
      public Tenant?: string
      public Username?: string
    }
  }
}

export namespace Adressen {
  export namespace GetAdresse {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
    }
    export class ResponseAdressenZustellbarkeit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAdressenStatusVerarbeitung {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAdressen {
      public GradUebereinstimmung!: number
      public Hausnummer!: string
      public Ort!: string
      public Ortsteil!: string
      public PLZ!: string
      public StatusVerarbeitung!: ResponseAdressenStatusVerarbeitung
      public Strasse!: string
      public Zustellbarkeit!: ResponseAdressenZustellbarkeit
    }
    export class Response {
      public Adressen!: ResponseAdressen[]
    }
  }
  export namespace GetOrte {
    export class Request {
      public Ort?: string
      public PLZ?: string
      public Sparte?: string
    }
    export class ResponseOrte {
      public IstBlacklist!: boolean
      public IstRandgebiet!: boolean
      public Name!: string
      public PLZ!: string
    }
    export class Response {
      public Orte!: ResponseOrte[]
    }
  }
}

export namespace Arbeitsauftraege {
  export namespace CreateAngebot {
    export class RequestAngebotsdaten { }
    export class Request {
      public AngebotGueltigAb?: DateString
      public AngebotGueltigBis?: DateString
      public Angebotsdaten?: RequestAngebotsdaten
      public Angebotsdatum?: DateString
      public Angebotsname?: string
      public ArbeitsauftragID?: string
      public BonusID?: string
      public BSCode?: string
      public GewuenschterVertragsbeginn?: DateString
      public Jahresverbrauch?: number
      public ProduktCode?: string
      public PSTCode?: string
      public Sparte?: string
      public Status?: string
    }
    export class Response {
      public Angebotsnummer!: string
    }
  }
  export namespace CreateArbeitsauftrag {
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAuftragsdaten { }
    export class RequestAngeboteAngebotsdaten { }
    export class RequestAngebote {
      public AngebotGueltigAb?: DateString
      public AngebotGueltigBis?: DateString
      public Angebotsdaten?: RequestAngeboteAngebotsdaten
      public Angebotsdatum?: DateString
      public Angebotsname?: string
      public BonusID?: string
      public BSCode?: string
      public GewuenschterVertragsbeginn?: DateString
      public Jahresverbrauch?: number
      public ProduktCode?: string
      public PST_Code?: string
      public Sparte?: string
      public Status?: string
    }
    export class Request {
      public Angebote?: RequestAngebote[]
      public ArbeitsauftragGueltigAb?: Date
      public ArbeitsauftragGueltigBis?: Date
      public Arbeitsauftragsgruppe?: string
      public Auftragsdaten?: RequestAuftragsdaten
      public Bearbeiter?: string
      public Dokumente?: RequestDokumente[]
      public ParentArbeitsauftragId?: string
      public Prozess?: string
      public Prozesskennzeichen?: string
      public Sparte?: string
      public Status?: string
      public Typ?: string
      public TypID?: string
    }
    export class ResponseWerte { }
    export class Response {
      public Angebotsnummern!: string[]
      public ArbeitsauftragID!: string
      public Code!: string
      public Werte!: ResponseWerte
    }
  }
  export namespace CreateArbeitsauftragStatus {
    export class RequestFelder { }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public ArbeitsauftragID?: string
      public Bearbeiter?: string
      public Dokumente?: RequestDokumente[]
      public Felder?: RequestFelder
      public Status?: string
      public Zeitstempel?: Date
    }
  }
  export namespace GetAngebot {
    export class RequestAngebotsdaten { }
    export class Request {
      public Angebotsdaten?: RequestAngebotsdaten
      public Angebotsnummer?: string
      public ArbeitsauftragCode?: string
      public ArbeitsauftragID?: string
      public Wildcard?: string
    }
    export class ResponseAngeboteAngebotsdaten { }
    export class ResponseAngebote {
      public AngebotAngenommenAm!: DateString
      public AngebotGueltigAb!: DateString
      public AngebotGueltigBis!: DateString
      public Angebotsdaten!: ResponseAngeboteAngebotsdaten
      public Angebotsdatum!: DateString
      public Angebotsname!: string
      public Angebotsnummer!: string
      public ArbeitsauftragID!: string
      public BonusID!: string
      public BSCode!: string
      public GewuenschterVertragsbeginn!: DateString
      public Jahresverbrauch!: number
      public ProduktCode!: string
      public PST_Code!: string
      public Sparte!: string
      public Status!: string
    }
    export class Response {
      public Angebote!: ResponseAngebote[]
    }
  }
  export namespace GetArbeitsauftrag {
    export class RequestAuftragsdaten { }
    export class Request {
      public AktuellerBearbeiter?: string
      public AktuellerVerantwortlicher?: string
      public ArbeitsauftragGueltigAb?: Date
      public ArbeitsauftragGueltigBis?: Date
      public ArbeitsauftragID?: string
      public Auftragsdaten?: RequestAuftragsdaten
      public Auftragsgruppe?: string
      public Prozesskennzeichen?: string
      public Status?: string
      public Typ?: string
      public TypID?: string
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseArbeitsauftraegeStatushistorieFelder { }
    export class ResponseArbeitsauftraegeStatushistorieDokumenteDokument {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseArbeitsauftraegeStatushistorieDokumente {
      public Dateityp!: string
      public Dokument!: ResponseArbeitsauftraegeStatushistorieDokumenteDokument
      public Name!: string
    }
    export class ResponseArbeitsauftraegeStatushistorie {
      public Bearbeiter!: string
      public Dokumente!: ResponseArbeitsauftraegeStatushistorieDokumente[]
      public Felder!: ResponseArbeitsauftraegeStatushistorieFelder
      public Status!: string
      public StatusAnzeigetext!: string
      public Zeitstempel!: Date
    }
    export class ResponseArbeitsauftraegeInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseArbeitsauftraegeDokumenteDokument {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseArbeitsauftraegeDokumente {
      public Dateityp!: string
      public Dokument!: ResponseArbeitsauftraegeDokumenteDokument
      public Name!: string
    }
    export class ResponseArbeitsauftraegeAuftragsdaten { }
    export class ResponseArbeitsauftraegeAngeboteAngebotsdaten { }
    export class ResponseArbeitsauftraegeAngebote {
      public AngebotAngenommenAm!: DateString
      public AngebotGueltigAb!: DateString
      public AngebotGueltigBis!: DateString
      public Angebotsdaten!: ResponseArbeitsauftraegeAngeboteAngebotsdaten
      public Angebotsdatum!: DateString
      public Angebotsname!: string
      public Angebotsnummer!: string
      public ArbeitsauftragID!: string
      public BonusID!: string
      public BSCode!: string
      public GewuenschterVertragsbeginn!: DateString
      public Jahresverbrauch!: number
      public ProduktCode!: string
      public PST_Code!: string
      public Sparte!: string
      public Status!: string
    }
    export class ResponseArbeitsauftraege {
      public AktuellerBearbeiter!: string
      public Angebote!: ResponseArbeitsauftraegeAngebote[]
      public ArbeitsauftragExterneReferenz!: string
      public ArbeitsauftragID!: string
      public AuftragGueltigAb!: Date
      public AuftragGueltigBis!: Date
      public Auftragsdaten!: ResponseArbeitsauftraegeAuftragsdaten
      public Auftragsgruppe!: string
      public Client!: string
      public Dokumente!: ResponseArbeitsauftraegeDokumente[]
      public Inhalte!: ResponseArbeitsauftraegeInhalte
      public Prozess!: string
      public Prozesskennzeichen!: string
      public Status!: string
      public StatusAnzeigetext!: string
      public Statushistorie!: ResponseArbeitsauftraegeStatushistorie[]
      public Typ!: string
      public TypID!: string
    }
    export class Response {
      public Arbeitsauftraege!: ResponseArbeitsauftraege[]
      public Referenzen!: ResponseReferenzen
    }
  }
  export namespace UpdateAngebot {
    export class RequestAngebotsdaten { }
    export class Request {
      public AngebotAngenommenAm?: DateString
      public AngebotGueltigAb?: DateString
      public AngebotGueltigBis?: DateString
      public Angebotsdaten?: RequestAngebotsdaten
      public Angebotsdatum?: DateString
      public Angebotsname?: string
      public Angebotsnummer?: string
      public BonusID?: string
      public BSCode?: string
      public GewuenschterVertragsbeginn?: DateString
      public Jahresverbrauch?: number
      public ProduktCode?: string
      public PST_Code?: string
      public Sparte?: string
      public Status?: string
    }
  }
}

export namespace Auftraege {
  export namespace CreateNeuauftrag {
    export class RequestWerte { }
    export class RequestVollmachtenAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class RequestVollmachten {
      public Adresse?: RequestVollmachtenAdresse
      public Anrede?: string
      public Name1_Nachname?: string
      public Name2_Vorname?: string
      public Titel?: string
    }
    export class RequestReferenz {
      public Angebotsnummer?: string
      public ArbeitsauftragID?: string
      public Auftragsnummer?: string
      public VK?: string
    }
    export class RequestRechnungsanschriftAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Postfach?: string
      public Strasse?: string
    }
    export class RequestRechnungsanschrift {
      public Adresse?: RequestRechnungsanschriftAdresse
      public Anrede?: string
      public Name1_Nachname?: string
      public Name2_Vorname?: string
    }
    export class RequestOptsPraeferenz {
      public Name?: string
      public Status?: boolean
    }
    export class RequestOptsEinwilligungen {
      public Kommunikationsweg?: string
      public Kontaktdaten?: string
      public Sparte?: string
      public Status?: string
    }
    export class RequestOpts {
      public Einwilligungen?: RequestOptsEinwilligungen[]
      public Praeferenz?: RequestOptsPraeferenz[]
      public RobinsonFlag?: boolean
    }
    export class RequestLieferadresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class RequestGeschaeftspartnerPrivatkundeZweiterVertragspartner {
      public Nachname?: string
      public Titel?: string
      public Vorname?: string
    }
    export class RequestGeschaeftspartnerPrivatkundeVertragspartnerKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestGeschaeftspartnerPrivatkundeVertragspartner {
      public Geburtsdatum?: DateString
      public Kontaktdaten?: RequestGeschaeftspartnerPrivatkundeVertragspartnerKontaktdaten
      public Nachname?: string
      public Titel?: string
      public Vorname?: string
    }
    export class RequestGeschaeftspartnerPrivatkunde {
      public Anrede?: string
      public Vertragspartner?: RequestGeschaeftspartnerPrivatkundeVertragspartner
      public ZweiterVertragspartner?: RequestGeschaeftspartnerPrivatkundeZweiterVertragspartner
    }
    export class RequestGeschaeftspartnerOrganisationKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestGeschaeftspartnerOrganisation {
      public Anrede?: string
      public Branche?: number
      public Handelsgericht?: string
      public Handelsregisternummer?: string
      public Kontaktdaten?: RequestGeschaeftspartnerOrganisationKontaktdaten
      public Name1?: string
      public Name2?: string
      public UmsatzsteuerId?: string
    }
    export class RequestGeschaeftspartner {
      public GP?: string
      public Organisation?: RequestGeschaeftspartnerOrganisation
      public Privatkunde?: RequestGeschaeftspartnerPrivatkunde
    }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAuftragsdetailsProdukteZahlungsinformationSEPA {
      public BIC?: string
      public Datenherkunft?: string
      public IBAN?: string
      public Kontoinhaber?: string
    }
    export class RequestAuftragsdetailsProdukteZahlungsinformationOnlineBezahldienst {
      public Benutzername?: string
      public Dienstleister?: string
      public Token?: string
    }
    export class RequestAuftragsdetailsProdukteZahlungsinformation {
      public OnlineBezahldienst?: RequestAuftragsdetailsProdukteZahlungsinformationOnlineBezahldienst
      public SEPA?: RequestAuftragsdetailsProdukteZahlungsinformationSEPA
      public Zahlungsrichtung?: string
    }
    export class RequestAuftragsdetailsProdukteWerte { }
    export class RequestAuftragsdetailsProdukteCommodityInformationenKundenvorversorgerVorversorger {
      public Code?: string
      public Name?: string
    }
    export class RequestAuftragsdetailsProdukteCommodityInformationenKundenvorversorger {
      public KuendigungsDatum?: DateString
      public Kundennummer?: string
      public Vorversorger?: RequestAuftragsdetailsProdukteCommodityInformationenKundenvorversorgerVorversorger
    }
    export class RequestAuftragsdetailsProdukteCommodityInformationen {
      public EinzugZaehlerstandHT?: number
      public EinzugZaehlerstandNT?: number
      public JahresverbrauchHT?: number
      public JahresverbrauchNT?: number
      public Kundenvorversorger?: RequestAuftragsdetailsProdukteCommodityInformationenKundenvorversorger
      public MarktlokationsId?: string
      public Transaktionsgrund?: string
      public Zaehlernummer?: string
    }
    export class RequestAuftragsdetailsProdukte {
      public BonusId?: string
      public BSCode?: string
      public CommodityInformationen?: RequestAuftragsdetailsProdukteCommodityInformationen
      public GewuenschterLieferbeginn?: Date
      public Menge?: number
      public ProduktCode?: string
      public PSTCode?: string
      public Sparte?: string
      public Werte?: RequestAuftragsdetailsProdukteWerte
      public Zahlungsinformation?: RequestAuftragsdetailsProdukteZahlungsinformation
    }
    export class RequestAuftragsdetails {
      public AngebotGueltigAb?: Date
      public AngebotGueltigBis?: Date
      public Auftragsdatum?: Date
      public Kundenart?: string
      public Produkte?: RequestAuftragsdetailsProdukte[]
      public VertriebspartnerName?: string
    }
    export class Request {
      public Auftragsdetails?: RequestAuftragsdetails
      public Dokumente?: RequestDokumente[]
      public Geschaeftspartner?: RequestGeschaeftspartner
      public Lieferadresse?: RequestLieferadresse
      public Opts?: RequestOpts
      public Rechnungsanschrift?: RequestRechnungsanschrift
      public Referenz?: RequestReferenz
      public Vollmachten?: RequestVollmachten[]
      public Werte?: RequestWerte
    }
    export class Response {
      public Auftragscode!: string
      public GP!: string
      public VK!: string
    }
  }
  export namespace CreateProduktwechsel {
    export class RequestWerte { }
    export class RequestOptsPraeferenz {
      public Name?: string
      public Status?: boolean
    }
    export class RequestOptsEinwilligungen {
      public Kommunikationsweg?: string
      public Kontaktdaten?: string
      public Sparte?: string
      public Status?: string
    }
    export class RequestOpts {
      public Einwilligungen?: RequestOptsEinwilligungen[]
      public Praeferenz?: RequestOptsPraeferenz[]
      public RobinsonFlag?: boolean
    }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAuftragsdetails {
      public AngebotGueltigAb?: DateString
      public AngebotGueltigBis?: DateString
      public Auftragsdatum?: DateString
      public BonusId?: string
      public BSCode?: string
      public ProduktCode?: string
      public Produktwechseldatum?: DateString
      public PSTCode?: string
      public VertriebspartnerName?: string
    }
    export class Request {
      public Auftragsdetails?: RequestAuftragsdetails
      public Dokumente?: RequestDokumente[]
      public Opts?: RequestOpts
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public Auftragscode!: string
    }
  }
  export namespace UpdateEinzug {
    export class RequestWerte { }
    export class RequestVorversorger {
      public Code?: string
      public Kundennummer?: string
    }
    export class Request {
      public Anrede?: string
      public Hausnummer?: string
      public HausnummerErgaenzung?: string
      public MarktlokationsId?: string
      public Nachname?: string
      public Ort?: string
      public PLZ?: string
      public Prozess?: string
      public Strasse?: string
      public Transaktionsgrund?: string
      public Vertragsnummer?: string
      public VK?: string
      public Vorname?: string
      public Vorversorger?: RequestVorversorger
      public Wechselbelegnummer?: string
      public Werte?: RequestWerte
      public Zaehlernummer?: string
    }
    export class Response { }
  }
}

export namespace Chat {
  export namespace AddChatUser {
    export class RequestChatUser {
      public System?: string
      public Tenant?: string
      public Username?: string
    }
    export class Request {
      public ChatUser?: RequestChatUser[]
      public ChatUserId?: string
    }
    export class Response { }
  }
  export namespace CreateChat {
    export class RequestWerte { }
    export class RequestInitialUsers {
      public System?: string
      public Tenant?: string
      public Username?: string
    }
    export class Request {
      public ArbeitsauftragID?: string
      public InitialUsers?: RequestInitialUsers[]
      public Name?: string
      public System?: string
      public Typ?: string
      public Werte?: RequestWerte
    }
    export class Response {
      public ChatId!: string
      public ChatUserId!: string
    }
  }
  export namespace GetChatNachrichten {
    export class Request {
      public AnzahlNachrichten?: number
      public ChatUserId?: string
      public GeheRueckwaerts?: boolean
      public LetzteChatNachrichtId?: string
      public WarteAufNachrichten?: boolean
    }
    export class ResponseChatUser {
      public AnzeigeName!: string
      public DarfNurLesen!: boolean
      public HatChatVerlassen!: boolean
      public IstBesitzer!: boolean
      public IstSystemUser!: boolean
      public ManSelbst!: boolean
      public PublicChatUserId!: string
      public System!: string
      public Tenant!: string
      public Username!: string
    }
    export class ResponseChatNachrichtenWerte { }
    export class ResponseChatNachrichtenAnhaengeDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseChatNachrichtenAnhaenge {
      public Dateigroesse!: number
      public DateiId!: ResponseChatNachrichtenAnhaengeDateiId
      public Dateiname!: string
      public Reihenfolge!: number
      public Vorschaubild!: string
    }
    export class ResponseChatNachrichten {
      public Anhaenge!: ResponseChatNachrichtenAnhaenge[]
      public ChatId!: string
      public ChatNachrichtId!: string
      public ErstelltAm!: Date
      public Nummer!: number
      public PublicChatUserId!: string
      public Text!: string
      public Typ!: string
      public Werte!: ResponseChatNachrichtenWerte
    }
    export class Response {
      public AnzahlUngelesenerNachrichten!: number
      public ArbeitsauftragId!: string
      public ChatId!: string
      public ChatNachrichten!: ResponseChatNachrichten[]
      public ChatName!: string
      public ChatStatus!: string
      public ChatTyp!: string
      public ChatUser!: ResponseChatUser[]
      public ChatUserId!: string
    }
  }
  export namespace GetChatUpdates {
    export class Request {
      public ChatUpdateId?: string
    }
    export class ResponseChatUpdatesChatUser {
      public AnzeigeName!: string
      public DarfNurLesen!: boolean
      public HatChatVerlassen!: boolean
      public IstBesitzer!: boolean
      public IstSystemUser!: boolean
      public ManSelbst!: boolean
      public PublicChatUserId!: string
      public System!: string
      public Tenant!: string
      public Username!: string
    }
    export class ResponseChatUpdatesChatNachrichtenWerte { }
    export class ResponseChatUpdatesChatNachrichtenAnhaengeDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseChatUpdatesChatNachrichtenAnhaenge {
      public Dateigroesse!: number
      public DateiId!: ResponseChatUpdatesChatNachrichtenAnhaengeDateiId
      public Dateiname!: string
      public Reihenfolge!: number
      public Vorschaubild!: string
    }
    export class ResponseChatUpdatesChatNachrichten {
      public Anhaenge!: ResponseChatUpdatesChatNachrichtenAnhaenge[]
      public ChatId!: string
      public ChatNachrichtId!: string
      public ErstelltAm!: Date
      public Nummer!: number
      public PublicChatUserId!: string
      public Text!: string
      public Typ!: string
      public Werte!: ResponseChatUpdatesChatNachrichtenWerte
    }
    export class ResponseChatUpdates {
      public AnzahlUngelesenerNachrichten!: number
      public ArbeitsauftragId!: string
      public ChatId!: string
      public ChatNachrichten!: ResponseChatUpdatesChatNachrichten[]
      public ChatName!: string
      public ChatStatus!: string
      public ChatTyp!: string
      public ChatUser!: ResponseChatUpdatesChatUser[]
      public ChatUserId!: string
    }
    export class Response {
      public ChatUpdateId!: string
      public ChatUpdates!: ResponseChatUpdates[]
    }
  }
  export namespace GetChats {
    export class Request {
      public Meine?: boolean
      public Offene?: boolean
      public Typ?: string
    }
    export class ResponseChatsWerte { }
    export class ResponseChatsLetzteChatNachrichtWerte { }
    export class ResponseChatsLetzteChatNachrichtAnhaengeDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseChatsLetzteChatNachrichtAnhaenge {
      public Dateigroesse!: number
      public DateiId!: ResponseChatsLetzteChatNachrichtAnhaengeDateiId
      public Dateiname!: string
      public Reihenfolge!: number
      public Vorschaubild!: string
    }
    export class ResponseChatsLetzteChatNachricht {
      public Anhaenge!: ResponseChatsLetzteChatNachrichtAnhaenge[]
      public ChatId!: string
      public ChatNachrichtId!: string
      public ErstelltAm!: Date
      public Nummer!: number
      public PublicChatUserId!: string
      public Text!: string
      public Typ!: string
      public Werte!: ResponseChatsLetzteChatNachrichtWerte
    }
    export class ResponseChatsChatUser {
      public AnzeigeName!: string
      public DarfNurLesen!: boolean
      public HatChatVerlassen!: boolean
      public IstBesitzer!: boolean
      public IstSystemUser!: boolean
      public ManSelbst!: boolean
      public PublicChatUserId!: string
      public System!: string
      public Tenant!: string
      public Username!: string
    }
    export class ResponseChats {
      public AnzahlUngelesenerNachrichten!: number
      public ArbeitsauftragID!: string
      public ChatId!: string
      public ChatUser!: ResponseChatsChatUser[]
      public ChatUserId!: string
      public LetzteChatNachricht!: ResponseChatsLetzteChatNachricht
      public LetzteGeleseneNachrichtNachrichtId!: string
      public LetzteNachricht!: string
      public LetzteNachrichtAm!: Date
      public LetzteNachrichtNachrichtId!: string
      public LetzteNachrichtVon!: string
      public Name!: string
      public Status!: string
      public System!: string
      public Typ!: string
      public Werte!: ResponseChatsWerte
    }
    export class Response {
      public Chats!: ResponseChats[]
      public ChatUpdateId!: string
    }
  }
  export namespace JoinChat {
    export class Request {
      public ChatId?: string
    }
    export class Response {
      public ChatUserId!: string
    }
  }
  export namespace LeaveChat {
    export class Request {
      public ChatUserId?: string
    }
    export class Response { }
  }
  export namespace SendChatNachricht {
    export class RequestWerteNachricht { }
    export class RequestWerteChat { }
    export class RequestAnhaenge {
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public Anhaenge?: RequestAnhaenge[]
      public ChatUserId?: string
      public Text?: string
      public Typ?: string
      public WerteChat?: RequestWerteChat
      public WerteNachricht?: RequestWerteNachricht
    }
    export class Response {
      public ChatNachrichtId!: string
    }
  }
  export namespace SetChatNachrichtGelesen {
    export class Request {
      public ChatUserId?: string
      public NeueGeleseneChatNachrichtId?: string
    }
    export class Response { }
  }
  export namespace UpdateChatName {
    export class Request {
      public ChatUserId?: string
      public NeuerChatName?: string
    }
    export class Response { }
  }
}

export namespace Dienstleister {
  export namespace GetDienstleisterInformation {
    export class Request {
      public Dienstleister?: string
      public Information?: string
      public Referenz?: string
    }
    export class ResponseErgebnisse {
      public Feld!: string
      public Wert!: string
    }
    export class Response {
      public Ergebnisse!: ResponseErgebnisse[]
    }
  }
  export namespace GetDienstleistung {
    export class Request {
      public Dienstleister?: string
      public Dienstleistung?: string
      public Referenz?: string
    }
    export class ResponseErgebnisse {
      public Feld!: string
      public Wert!: string
    }
    export class Response {
      public Ergebnisse!: ResponseErgebnisse[]
    }
  }
  export namespace UpdateDienstleistung {
    export class RequestWerte { }
    export class Request {
      public Aktion?: string
      public Dienstleister?: string
      public Dienstleistung?: string
      public Referenz?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseErgebnisse {
      public Feld!: string
      public Wert!: string
    }
    export class Response {
      public Ergebnisse!: ResponseErgebnisse[]
    }
  }
}

export namespace Filterwerte {
  export namespace CheckFilterwert {
    export class RequestFilterwerte { }
    export class Request {
      public Filterwerte?: RequestFilterwerte
      public Prozesskennzeichen?: string
    }
    export class ResponseErgebnisse {
      public Id!: string
      public Rueckgabe!: string
      public Rueckgabegrund!: string
    }
    export class Response {
      public Ergebnisse!: ResponseErgebnisse[]
    }
  }
  export namespace CreateFilterwert {
    export class RequestFilterwerte { }
    export class Request {
      public Filterwerte?: RequestFilterwerte
      public GueltigAb?: DateString
      public Prozesskennzeichen?: string
      public Rueckgabe?: string
      public Rueckgabegrund?: string
    }
    export class Response { }
  }
  export namespace DeleteFilterwert {
    export class RequestFilterwerte { }
    export class Request {
      public Filterwerte?: RequestFilterwerte
      public GueltigAb?: Date
      public Prozesskennzeichen?: string
    }
    export class Response { }
  }
}

export namespace Geschaeftspartner {
  export namespace CreateGP {
    export class RequestWerte { }
    export class RequestOptsPraeferenz {
      public Name?: string
      public Status?: boolean
    }
    export class RequestOptsEinwilligungen {
      public Kommunikationsweg?: string
      public Kontaktdaten?: string
      public Sparte?: string
      public Status?: string
    }
    export class RequestOpts {
      public Einwilligungen?: RequestOptsEinwilligungen[]
      public Praeferenz?: RequestOptsPraeferenz[]
      public RobinsonFlag?: boolean
    }
    export class RequestGPPrivatkundeZweiterVertragspartner {
      public Nachname?: string
      public Titel?: string
      public Vorname?: string
    }
    export class RequestGPPrivatkundeVertragspartnerKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestGPPrivatkundeVertragspartner {
      public Geburtsdatum?: DateString
      public Kontaktdaten?: RequestGPPrivatkundeVertragspartnerKontaktdaten
      public Nachname?: string
      public Titel?: string
      public Vorname?: string
    }
    export class RequestGPPrivatkunde {
      public Anrede?: string
      public Vertragspartner?: RequestGPPrivatkundeVertragspartner
      public ZweiterVertragspartner?: RequestGPPrivatkundeZweiterVertragspartner
    }
    export class RequestGPOrganisationKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestGPOrganisation {
      public Anrede?: string
      public Branche?: number
      public Handelsgericht?: string
      public Handelsregisternummer?: string
      public Kontaktdaten?: RequestGPOrganisationKontaktdaten
      public Name1?: string
      public Name2?: string
      public UmsatzsteuerId?: string
    }
    export class RequestGP {
      public Organisation?: RequestGPOrganisation
      public Privatkunde?: RequestGPPrivatkunde
    }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Postfach?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public Dokumente?: RequestDokumente[]
      public GP?: RequestGP
      public Opts?: RequestOpts
      public Werte?: RequestWerte
    }
    export class ResponseGP {
      public Anzeigetext!: string
      public Art!: string
      public ReferenzID!: string
    }
    export class ResponseAbweichendeAdresse {
      public Hausnummer!: string
      public Land!: string
      public Ort!: string
      public PLZ!: string
      public Postfach!: string
      public Strasse!: string
    }
    export class Response {
      public AbweichendeAdresse!: ResponseAbweichendeAdresse
      public GP!: ResponseGP
    }
  }
}

export namespace Gutschein {
  export namespace CheckGutscheinCode {
    export class Request {
      public Bonustyp?: string
      public GutscheinCode?: string
    }
    export class Response {
      public Abrufdatum!: DateString
      public AktivierungsCode!: string
      public GueltigBis!: DateString
      public GutscheinCode!: string
      public Referenz!: string
      public Wert!: number
    }
  }
  export namespace GetGutscheinCode {
    export class Request {
      public Bonustyp?: string
      public Referenz?: string
      public Wert?: number
    }
    export class Response {
      public AktivierungsCode!: string
      public GueltigBis!: DateString
      public GutscheinCode!: string
    }
  }
}

export namespace Inhalte {
  export namespace CheckDokument {
    export class RequestWerte { }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public Dokumente?: RequestDokumente[]
      public Prozess?: string
      public Werte?: RequestWerte
    }
    export class ResponseWerte { }
    export class Response {
      public Prozess!: string
      public Werte!: ResponseWerte
    }
  }
  export namespace CreateDateiArchivierung {
    export class RequestWerte { }
    export class Request {
      public AC_Code?: string
      public Belegart?: string
      public Dokumentenzeit?: Date
      public GP?: string
      public Inhalt?: string
      public Kontaktart?: string
      public Name?: string
      public NotVisibleBeforeDate?: Date
      public Richtung?: string
      public Tenant?: string
      public Username?: string
      public VK?: string
      public VP_Code?: string
      public Werte?: RequestWerte
    }
    export class ResponseDocumentId {
      public DokumentId!: string
      public SystemId!: string
    }
    export class Response {
      public DocumentId!: ResponseDocumentId
    }
  }
  export namespace CreateDokument {
    export class RequestWerte { }
    export class RequestDokumentDateiID {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokument {
      public DateiID?: RequestDokumentDateiID
      public Daten?: string
    }
    export class Request {
      public Dokument?: RequestDokument
      public Dokumentenart?: string
      public VorschaubildErwuenscht?: boolean
      public Werte?: RequestWerte
    }
    export class ResponseVorschaubild {
      public DateiTyp!: string
      public Daten!: string
      public Name!: string
    }
    export class ResponseDokument {
      public DateiTyp!: string
      public Daten!: string
      public Name!: string
    }
    export class Response {
      public Dokument!: ResponseDokument
      public Vorschaubild!: ResponseVorschaubild
    }
  }
  export namespace CreateKundendokument {
    export class RequestWerte {
      public Feld?: string
      public Inhalt?: string
    }
    export class RequestHeader {
      public Feld?: string
      public Inhalt?: string
    }
    export class RequestEmpfaenger {
      public Inhalt?: string
      public Typ?: string
    }
    export class RequestDokumente {
      public Inhalt?: string
      public Mime?: string
      public Name?: string
    }
    export class Request {
      public Beschreibung?: string
      public Dokumentart?: string
      public Dokumente?: RequestDokumente[]
      public Empfaenger?: RequestEmpfaenger[]
      public Header?: RequestHeader[]
      public Prioritaet?: string
      public Tenant?: string
      public Typ?: string
      public Vorlage?: string
      public Werte?: RequestWerte[]
      public ZeitpunktNachrichtSenden?: Date
      public ZeitraumNachrichtSenden?: string
    }
    export class Response {
      public Anmerkungen!: string[]
      public NachrichtenIds!: string[]
    }
  }
  export namespace GetDateien {
    export class RequestDateien {
      public DateiId?: string
      public SystemId?: string
    }
    export class Request {
      public Dateien?: RequestDateien[]
      public DateiErwuenscht?: boolean
      public PdfAnhaengeExtrahieren?: boolean
      public VorschaubildErwuenscht?: boolean
    }
    export class ResponseDateienVorschaubild {
      public Anzeigetext!: string
      public Daten!: string
      public Name!: string
      public Typ!: string
    }
    export class ResponseDateienPdfAnhaenge {
      public Anzeigetext!: string
      public Daten!: string
      public Name!: string
      public Typ!: string
    }
    export class ResponseDateienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseDateienDatei {
      public Anzeigetext!: string
      public Daten!: string
      public Name!: string
      public Typ!: string
    }
    export class ResponseDateien {
      public Datei!: ResponseDateienDatei
      public DateiId!: ResponseDateienDateiId
      public PdfAnhaenge!: ResponseDateienPdfAnhaenge[]
      public Vorschaubild!: ResponseDateienVorschaubild
    }
    export class Response {
      public Dateien!: ResponseDateien[]
    }
  }
  export namespace GetFelder {
    export class RequestWerte { }
    export class Request {
      public Prozess?: string
      public Werte?: RequestWerte
    }
    export class ResponseProzessFelderProperties { }
    export class ResponseProzessFelder {
      public Feld!: string
      public Properties!: ResponseProzessFelderProperties
      public Wert!: string
    }
    export class ResponseProzess {
      public Felder!: ResponseProzessFelder[]
      public Prozess!: string
    }
    export class Response {
      public Prozess!: ResponseProzess[]
    }
  }
  export namespace GetInhalt {
    export class Request {
      public Hauptkategorie?: string
      public LetzterStandVom?: DateString
      public Presenter?: string
      public Typ?: string
      public Version?: string
    }
    export class ResponseTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseDateienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseDateienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseDateien {
      public DateiId!: ResponseDateienDateiId
      public DateiTyp!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseDateienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseContent {
      public Bezeichnung!: string
      public ContentId!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class Response {
      public Content!: ResponseContent[]
      public Dateien!: ResponseDateien[]
      public Parameter!: ResponseParameter[]
      public Texte!: ResponseTexte[]
    }
  }
  export namespace GetKundendokumente {
    export class RequestWerte { }
    export class Request {
      public AnzahlLetzteDokumente?: number
      public DokumentenArt?: string
      public DokumentenTyp?: string
      public Empfaenger?: string
      public ErstelldatumBis?: Date
      public ErstelldatumVon?: Date
      public NachrichtId?: string
      public Richtung?: string
      public Werte?: RequestWerte
    }
    export class ResponseKundendokumenteWerte { }
    export class ResponseKundendokumenteArchiviertesDokumentDokument {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseKundendokumenteArchiviertesDokument {
      public Dateityp!: string
      public Dokument!: ResponseKundendokumenteArchiviertesDokumentDokument
      public Name!: string
    }
    export class ResponseKundendokumenteAngehaengteDokumenteDokument {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseKundendokumenteAngehaengteDokumente {
      public Dateityp!: string
      public Dokument!: ResponseKundendokumenteAngehaengteDokumenteDokument
      public Name!: string
    }
    export class ResponseKundendokumente {
      public AngehaengteDokumente!: ResponseKundendokumenteAngehaengteDokumente[]
      public ArchiviertesDokument!: ResponseKundendokumenteArchiviertesDokument
      public DokumentenArt!: string
      public DokumentenTyp!: string
      public Empfaenger!: string
      public Erstelldatum!: Date
      public Name!: string
      public Richtung!: string
      public Status!: string
      public StatusAnzeigetext!: string
      public Vorlage!: string
      public Werte!: ResponseKundendokumenteWerte
      public ZeitpunktVersand!: Date
    }
    export class Response {
      public Kundendokumente!: ResponseKundendokumente[]
    }
  }
  export namespace GetMarktpartnerdaten {
    export class Request {
      public Code?: string
    }
    export class ResponseMarktpartner {
      public Bereich!: string
      public Code!: string
      public EdiAnsprechpartnerEmail!: string
      public EdiAnsprechpartnerFax!: string
      public EdiAnsprechpartnerTel!: string
      public EicCodeNr!: string
      public Fax!: string
      public GueltigSeit!: DateString
      public Hausnr!: string
      public Name!: string
      public Ort!: string
      public Plz!: string
      public PlzPostfach!: string
      public Postfach!: string
      public Sparte!: string
      public Strasse!: string
      public Tel!: string
    }
    export class Response {
      public Marktpartner!: ResponseMarktpartner[]
    }
  }
}

export namespace Kontakt {
  export namespace CreateKontaktnachricht {
    export class RequestWerte { }
    export class RequestAnhaenge {
      public Content?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public Anhaenge?: RequestAnhaenge[]
      public Grund?: string
      public Nachricht?: string
      public VK?: string
      public Werte?: RequestWerte
    }
  }
}

export namespace Logging {
  export namespace CreateLogItems {
    export class RequestExtendedData {
      public Name?: string
      public Wert?: string
    }
    export class Request {
      public Code?: string
      public Database?: string
      public EntityId?: string
      public EntityType?: string
      public ExtendedData?: RequestExtendedData[]
      public LogId?: string
      public LogType?: string
      public Message?: string
      public MessageDetail?: string
      public MessageDetailType?: string
      public Server?: string
      public Source?: string
      public System?: string
      public SystemVersion?: string
      public Username?: string
    }
  }
  export namespace CreateRequestLogItems {
    export class Request {
      public Database?: string
      public Duration?: number
      public ErrorCode?: string
      public HTTPStatusCode?: number
      public LogId?: string
      public LogType?: string
      public Server?: string
      public ServiceName?: string
      public System?: string
      public SystemVersion?: string
      public UserHostAddress?: string
      public Username?: string
    }
  }
}

export namespace Nachricht {
  export namespace ClearNachrichtenArchivQueue {
    export class Request {
      public FehlerhafteNachrichten?: string[]
      public MaxRetryTime?: number
      public NichtArchivierbareNachrichten?: string[]
      public ProzessId?: string
      public RetryTime?: number
    }
    export class Response {
      public QueueStatusOk!: boolean
    }
  }
  export namespace ClearNachrichtenVersandQueue {
    export class Request {
      public FehlerhafteNachrichten?: string[]
      public MaxRetryTime?: number
      public ProzessId?: string
      public RetryTime?: number
    }
    export class Response {
      public QueueStatusOk!: boolean
    }
  }
  export namespace CreateKommunikation {
    export class RequestWerte { }
    export class RequestPostanschriftAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Postfach?: string
      public Strasse?: string
    }
    export class RequestPostanschrift {
      public Adresse?: RequestPostanschriftAdresse
      public Anrede?: string
      public Nachname?: string
      public Vorname?: string
    }
    export class Request {
      public BonusID?: string
      public BSCode?: string
      public Dokumentenversandart?: string
      public Emailadresse?: string
      public GP?: string
      public Mobilnummer?: string
      public Postanschrift?: RequestPostanschrift
      public ProductCode?: string
      public ProzessID?: string
      public PSTCode?: string
      public PushIDs?: string[]
      public Referenzdatum?: DateString
      public Tenant?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response { }
  }
  export namespace CreateNachricht {
    export class RequestWerte {
      public Feld?: string
      public Inhalt?: string
    }
    export class RequestEmpfaengerListe {
      public Inhalt?: string
      public Typ?: string
    }
    export class RequestDokumente {
      public Inhalt?: string
      public Name?: string
    }
    export class Request {
      public Beschreibung?: string
      public Dokumente?: RequestDokumente[]
      public EmpfaengerListe?: RequestEmpfaengerListe[]
      public NachrichtTyp?: string
      public Prioritaet?: string
      public VorlageId?: string
      public Werte?: RequestWerte[]
      public ZeitpunktNachrichtSenden?: Date
      public ZeitraumNachrichtSenden?: string
    }
    export class Response {
      public NachrichtId!: string
    }
  }
  export namespace GetArchivNachrichten {
    export class Request {
      public AnzahlNachrichten?: number
      public ProzessId?: string
    }
    export class ResponseNachrichten {
      public Archivierbar!: boolean
      public ClientId!: string
      public Erstellt!: DateString
      public ExternalId!: string
      public NachrichtId!: string
      public ProzessId!: string
      public Typ!: string
      public Vorlage!: string
    }
    export class Response {
      public Nachrichten!: ResponseNachrichten[]
    }
  }
  export namespace GetNachricht {
    export class RequestWerte { }
    export class Request {
      public Empfaenger?: string
      public ExternalId?: string
      public ID?: string
      public Werte?: RequestWerte
    }
    export class ResponseNachrichtenWerte { }
    export class ResponseNachrichtenDokument {
      public Archiv!: string
      public ID!: string
    }
    export class ResponseNachrichtenBounceKategorie {
      public Code!: string
      public Klartext!: string
    }
    export class ResponseNachrichten {
      public Beschreibung!: string
      public BounceKategorie!: ResponseNachrichtenBounceKategorie
      public Dokument!: ResponseNachrichtenDokument
      public Empfaenger!: string
      public External_ID!: string
      public ID!: string
      public ID_Vorlage!: string
      public Info_Typ!: string
      public Status!: string
      public Werte!: ResponseNachrichtenWerte
      public Zeitpunkt_Versand!: DateString
      public Zustellbarkeit!: number
    }
    export class Response {
      public Nachrichten!: ResponseNachrichten[]
    }
  }
  export namespace SetNachrichtenToVersandQueue {
    export class Request {
      public AnzahlNachrichten?: number
      public MaximalAlter?: number
      public ProzessId?: string
    }
    export class ResponseNachrichten {
      public ClientId!: string
      public Empfaenger!: string
      public Erstellt!: DateString
      public NachrichtId!: string
      public ProzessId!: string
      public Typ!: string
      public Vorlage!: string
    }
    export class Response {
      public Nachrichten!: ResponseNachrichten[]
    }
  }
  export namespace UpdateNachricht {
    export class RequestDokument {
      public DateiId?: string
      public SystemId?: string
    }
    export class Request {
      public BounceKategorie?: string
      public Dokument?: RequestDokument
      public Empfaenger?: string
      public EmpfaengerPruefen?: boolean
      public External_Id?: string
      public Id?: string
      public Nachricht_Versand?: Date
      public Status?: string
      public Zustellbarkeit?: number
    }
    export class ResponseNachrichten {
      public Id!: string
      public OriginalZustellbarkeit!: number
    }
    export class Response {
      public Nachrichten!: ResponseNachrichten[]
    }
  }
  export namespace UpdateNachrichtenStatus {
    export class RequestNachrichten {
      public Beschreibung?: string
      public ExternalId?: string
      public Id?: string
      public Status?: string
    }
    export class Request {
      public Nachrichten?: RequestNachrichten[]
    }
    export class Response {
      public NachrichtenStatusOk!: boolean
    }
  }
  export namespace UpdatePushregistrierung {
    export class Request {
      public AppId?: string
      public Geraet?: string
      public GP?: string
      public Loeschen?: boolean
      public System?: string
      public Username?: string
    }
    export class Response { }
  }
}

export namespace Netzentgelte {
  export namespace GetNNEGas {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public Jahresverbrauch?: number
      public NetzId?: number
      public ProduktCode?: string[]
      public Zaehlergroesse?: string
    }
    export class ResponseZaehlergroesse {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreis {
      public Einheit!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreisEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreis {
      public Einheit!: ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreisEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponentenJahreskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenJahreskosten {
      public Einheit!: ResponseProdukteSummenPreiskomponentenJahreskostenEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponenten {
      public Jahreskosten!: ResponseProdukteSummenPreiskomponentenJahreskosten
      public PreiskomponentenGrundpreis!: ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreis
      public PreiskomponentenVerbrauchspreis!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreis
    }
    export class ResponseProduktePreiskomponentenTyp {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenJahreskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenJahreskosten {
      public Einheit!: ResponseProduktePreiskomponentenJahreskostenEinheit
      public Wert!: number
    }
    export class ResponseProduktePreiskomponentenEinzelbetragEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenEinzelbetrag {
      public Einheit!: ResponseProduktePreiskomponentenEinzelbetragEinheit
      public Wert!: number
    }
    export class ResponseProduktePreiskomponenten {
      public Anzeigetext!: string
      public Code!: string
      public Einzelbetrag!: ResponseProduktePreiskomponentenEinzelbetrag
      public Jahreskosten!: ResponseProduktePreiskomponentenJahreskosten
      public Typ!: ResponseProduktePreiskomponentenTyp
    }
    export class ResponseProdukte {
      public Preiskomponenten!: ResponseProduktePreiskomponenten[]
      public ProduktCode!: string
      public SummenPreiskomponenten!: ResponseProdukteSummenPreiskomponenten
    }
    export class ResponseNetzbetreiber {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseDruckstufe {
      public Anzeigetext!: string
      public Code!: string
    }
    export class Response {
      public Druckstufe!: ResponseDruckstufe
      public Mwst!: number
      public Netzbetreiber!: ResponseNetzbetreiber
      public Produkte!: ResponseProdukte[]
      public Zaehlerart!: ResponseZaehlerart
      public Zaehlergroesse!: ResponseZaehlergroesse
    }
  }
  export namespace GetNNEGasDetails {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public ArbeitZeitraum?: number
      public Aufteilung?: string
      public Druckstufe?: string
      public Hoechstleistung?: number
      public Jahresverbrauch?: number
      public KA?: string
      public Kundentyp?: string
      public Lieferbeginn?: DateString
      public Lieferende?: DateString
      public Messkosten?: string
      public Netzart?: string
      public NetzId?: string
      public Zaehlerart?: string
      public Zaehlergroesse?: number
    }
    export class ResponseNNE_GASLieferstelle {
      public Hausnummer!: string
      public Ort!: string
      public PLZ!: string
      public Strasse!: string
    }
    export class ResponseNNE_GAS {
      public AnzahlTage!: number
      public BemerkungArbeit!: string
      public Bemerkungen!: string[]
      public BemerkungKA!: string
      public BemerkungLeistung!: string
      public BemerkungNetz!: string
      public Bundesland!: string
      public Druckstufe!: string
      public DurchschnittGesamtCtKWh!: number
      public DurchschnittNNE_ohne_KA_ctkWh!: number
      public ErrechneteArbeit!: number
      public ErrechneteLeistung!: number
      public FehlerID!: string
      public Fehlertext!: string
      public Gasgruppe!: string
      public GrundpreisArbeit!: number
      public GrundpreisLeistung!: number
      public GrundpreisMessAblesung!: number
      public GrundpreisMessAblesungEinheit!: string
      public GrundpreisMessAbrechnung!: number
      public GrundpreisMessAbrechnungEinheit!: string
      public GrundpreisMessBetrieb!: number
      public GrundpreisMessBetriebEinheit!: string
      public KalkuliertesNetz!: string
      public KostenArbeitCtKWh!: number
      public KostenArbeitEur!: number
      public KostenArbeitGesamtEur!: number
      public KostenArbeitGrundEur!: number
      public KostenGesamtEur!: number
      public KostenKACtKWh!: number
      public KostenKAEur!: number
      public KostenLeistungEur!: number
      public KostenLeistungEurKW!: number
      public KostenLeistungGesamtEur!: number
      public KostenLeistungGrundEur!: number
      public KostenMessAblesungEur!: number
      public KostenMessAbrechnungEur!: number
      public KostenMessBetriebEur!: number
      public KostenNNE!: number
      public KostenNNEOhneKAEur!: number
      public Lieferbeginn!: DateString
      public Lieferende!: DateString
      public Lieferstelle!: ResponseNNE_GASLieferstelle
      public MDLBemerkungMess!: string
      public MDLKomponentenImMesspreis!: string
      public MDLName!: string
      public MehrMinderMengenKosten!: number
      public MG1Bemerkung!: string
      public MG1Bezeichnung!: string
      public MG1REKostenEur!: number
      public MG1REPreisCtKWh!: number
      public MG2Bemerkung!: string
      public MG2Bezeichnung!: string
      public MG2REKostenEur!: number
      public MG2REPreisCtKWh!: number
      public MSBBemerkungMess!: string
      public MSBKomponentenImMesspreis!: string
      public MSBName!: string
      public NetzbetreiberDVGW!: string
      public NetzbetreiberName!: string
      public NetzbetreiberStatus!: string
      public StandEnetDB!: string
      public Tarifgebiet!: string
      public Zaehlergroesse!: string
      public Zaehlertyp!: string
    }
    export class Response {
      public NNE_GAS!: ResponseNNE_GAS[]
    }
  }
  export namespace GetNNEStrom {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public Jahresleistung?: number
      public Jahresverbrauch?: number
      public NetzId?: number
      public ProduktCode?: string[]
      public Spannungsebene?: string
      public Zaehlerart?: string
    }
    export class ResponseProdukteZaehlerart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisNTEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisNT {
      public Einheit!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisNTEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisHTEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisHT {
      public Einheit!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisHTEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreisEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreis {
      public Einheit!: ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreisEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponentenJahreskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteSummenPreiskomponentenJahreskosten {
      public Einheit!: ResponseProdukteSummenPreiskomponentenJahreskostenEinheit
      public Wert!: number
    }
    export class ResponseProdukteSummenPreiskomponenten {
      public Jahreskosten!: ResponseProdukteSummenPreiskomponentenJahreskosten
      public PreiskomponentenGrundpreis!: ResponseProdukteSummenPreiskomponentenPreiskomponentenGrundpreis
      public PreiskomponentenVerbrauchspreisHT!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisHT
      public PreiskomponentenVerbrauchspreisNT!: ResponseProdukteSummenPreiskomponentenPreiskomponentenVerbrauchspreisNT
    }
    export class ResponseProdukteSpannungsebene {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenTyp {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenJahreskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenJahreskosten {
      public Einheit!: ResponseProduktePreiskomponentenJahreskostenEinheit
      public Wert!: number
    }
    export class ResponseProduktePreiskomponentenEinzelbetragEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiskomponentenEinzelbetrag {
      public Einheit!: ResponseProduktePreiskomponentenEinzelbetragEinheit
      public Wert!: number
    }
    export class ResponseProduktePreiskomponenten {
      public Anzeigetext!: string
      public Code!: string
      public Einzelbetrag!: ResponseProduktePreiskomponentenEinzelbetrag
      public Jahreskosten!: ResponseProduktePreiskomponentenJahreskosten
      public Typ!: ResponseProduktePreiskomponentenTyp
    }
    export class ResponseProdukteNetzbetreiber {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukte {
      public Netzbetreiber!: ResponseProdukteNetzbetreiber
      public Preiskomponenten!: ResponseProduktePreiskomponenten[]
      public ProduktCode!: string
      public Spannungsebene!: ResponseProdukteSpannungsebene
      public SummenPreiskomponenten!: ResponseProdukteSummenPreiskomponenten
      public Zaehlerart!: ResponseProdukteZaehlerart
    }
    export class Response {
      public Jahresleistung!: number
      public Mwst!: number
      public Produkte!: ResponseProdukte[]
    }
  }
  export namespace GetNNEStromDetails {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public Hoechstleistung?: number
      public Jahresarbeit?: number
      public Jahresarbeit_NT?: number
      public Lieferbeginn?: DateString
      public Lieferende?: DateString
      public NetzID?: string
      public Spannungsebene?: string
      public Zaehlerart?: string
    }
    export class ResponseZaehlerart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseSpannungsebene {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseNNE {
      public AP_HT!: number
      public AP_NT!: number
      public GP!: number
      public Gueltig_Bis!: DateString
      public Gueltig_Von!: DateString
      public KA_HT!: number
      public KA_NT!: number
      public LP!: number
      public MP_Abrechnung!: number
      public MP_Betrieb!: number
      public MP_Dienstleistung!: number
    }
    export class ResponseNetzbetreiber {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseNetz {
      public Anzeigetext!: string
      public Code!: string
    }
    export class Response {
      public Benutzungsstunden!: number
      public Netz!: ResponseNetz
      public Netzbetreiber!: ResponseNetzbetreiber
      public NNE!: ResponseNNE[]
      public Spannungsebene!: ResponseSpannungsebene
      public Zaehlerart!: ResponseZaehlerart
    }
  }
  export namespace GetNetze {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public NetzNrName?: string
      public SpannungsebeneDruckStufe?: string
      public Sparte?: string
    }
    export class ResponseNetze {
      public Name!: string
      public NetzId!: string
    }
    export class Response {
      public Netze!: ResponseNetze[]
      public SpannungsebeneDruckStufe!: string
    }
  }
  export namespace GetZaehlerkosten {
    export class Request {
      public Netznummer?: string
      public Ort?: string
      public PLZ?: string
      public VK?: string
    }
    export class ResponsePreiskomponentenMonatskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponsePreiskomponentenMonatskosten {
      public Brutto!: number
      public Einheit!: ResponsePreiskomponentenMonatskostenEinheit
      public Netto!: number
    }
    export class ResponsePreiskomponentenJahreskostenEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponsePreiskomponentenJahreskosten {
      public Brutto!: number
      public Einheit!: ResponsePreiskomponentenJahreskostenEinheit
      public Netto!: number
    }
    export class ResponsePreiskomponenten {
      public Jahreskosten!: ResponsePreiskomponentenJahreskosten
      public Monatskosten!: ResponsePreiskomponentenMonatskosten
      public Standard!: boolean
      public Zaehlertyp!: string
    }
    export class ResponseNetzbetreiber {
      public Anzeigetext!: string
      public Code!: string
    }
    export class Response {
      public GueltigAb!: DateString
      public MwSt!: number
      public Netzbetreiber!: ResponseNetzbetreiber
      public Preiskomponenten!: ResponsePreiskomponenten[]
    }
  }
}

export namespace Opts {
  export namespace GetOpts {
    export class Request {
      public GP?: string[]
      public OptId?: string
      public VK?: string[]
    }
    export class ResponseResultPraeferenz {
      public Anzeigetext!: string
      public DatumErfassung!: DateString
      public Eingangskanal!: string
      public EingangskanalAnzeigetext!: string
      public Name!: string
      public Status!: boolean
    }
    export class ResponseResultGP {
      public Anzeigetext!: string
      public ReferenzID!: string
    }
    export class ResponseResultEinwilligungen {
      public DatumErfassung!: DateString
      public Eingangskanal!: string
      public EingangskanalAnzeigetext!: string
      public Kommunikationsweg!: string
      public KommunikationswegAnzeigetext!: string
      public Kontaktdaten!: string
      public Sparte!: string
      public SparteAnzeigetext!: string
      public Status!: string
    }
    export class ResponseResult {
      public Einwilligungen!: ResponseResultEinwilligungen[]
      public GP!: ResponseResultGP
      public Praeferenz!: ResponseResultPraeferenz[]
      public RobinsonFlagGesetzt!: boolean
    }
    export class Response {
      public Result!: ResponseResult[]
    }
  }
  export namespace UpdateOpts {
    export class RequestWerte { }
    export class RequestOptsPraeferenz {
      public Name?: string
      public Status?: boolean
    }
    export class RequestOptsEinwilligungen {
      public Kommunikationsweg?: string
      public Kontaktdaten?: string
      public Sparte?: string
      public Status?: string
    }
    export class RequestOptsDateiAnhaenge {
      public Dateiname?: string
      public DateiTyp?: string
      public Daten?: string
    }
    export class RequestOpts {
      public DateiAnhaenge?: RequestOptsDateiAnhaenge[]
      public Einwilligungen?: RequestOptsEinwilligungen[]
      public GP?: string
      public Marketinginteressentennummer?: string
      public Praeferenz?: RequestOptsPraeferenz[]
      public RobinsonFlag?: boolean
    }
    export class Request {
      public Code?: string
      public Opts?: RequestOpts[]
      public Werte?: RequestWerte
    }
    export class Response {
      public Code!: string
      public OptId!: string
    }
  }
}

export namespace Produkt {
  export namespace CheckProdukt {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public ACCode?: string
      public Adresse?: RequestAdresse
      public AngebotAngenommenAm?: DateString
      public AngebotGueltigBis?: DateString
      public BonusID?: string
      public BSCode?: string
      public Kundenart?: string
      public ProduktCode?: string
      public PSTCode?: string
      public Sparte?: string
      public Vertragsbeginn?: DateString
    }
    export class Response {
      public ProduktVorhanden!: boolean
    }
  }
  export namespace GetBestandsprodukt {
    export class RequestWerte { }
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public AngebotVom?: DateString
      public BonusID?: string
      public BSCode?: string
      public Jahresverbrauch?: number
      public ProduktCode?: string
      public PSTCode?: string
      public VertragAb?: DateString
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseBestandsproduktUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseBestandsproduktSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseBestandsproduktPreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseBestandsproduktPreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseBestandsproduktPreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseBestandsproduktPreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseBestandsproduktPreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseBestandsproduktPreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseBestandsproduktPreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseBestandsproduktKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseBestandsproduktKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktKostenvergleich {
      public Differenz!: ResponseBestandsproduktKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseBestandsproduktKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseBestandsproduktKostenBonusAngerechnet
      public Jahreskosten!: ResponseBestandsproduktKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseBestandsproduktInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseBestandsproduktFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseBestandsproduktFristen {
      public Bindefrist!: ResponseBestandsproduktFristenBindefrist
      public Folgelaufzeit!: ResponseBestandsproduktFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseBestandsproduktFristenKuendigungsfrist
      public KuendigungZum!: ResponseBestandsproduktFristenKuendigungZum
      public Vertragslaufzeit!: ResponseBestandsproduktFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseBestandsproduktFristenWiderrufsfrist
      public Zahlungsziel!: ResponseBestandsproduktFristenZahlungsziel
    }
    export class ResponseBestandsproduktFelder { }
    export class ResponseBestandsproduktCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseBestandsproduktBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseBestandsproduktBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseBestandsproduktBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseBestandsproduktBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseBestandsproduktBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseBestandsproduktBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseBestandsproduktBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseBestandsproduktBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseBestandsproduktBonussteuerung {
      public Bonuswerte!: ResponseBestandsproduktBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseBestandsproduktBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseBestandsprodukt {
      public Bonussteuerung!: ResponseBestandsproduktBonussteuerung
      public CrossSellProdukte!: ResponseBestandsproduktCrossSellProdukte[]
      public Felder!: ResponseBestandsproduktFelder
      public Fristen!: ResponseBestandsproduktFristen
      public Inhalte!: ResponseBestandsproduktInhalte
      public Kosten!: ResponseBestandsproduktKosten[]
      public Kostenvergleich!: ResponseBestandsproduktKostenvergleich[]
      public Kundenart!: ResponseBestandsproduktKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseBestandsproduktPreisgarantie
      public Preiszeitscheiben!: ResponseBestandsproduktPreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseBestandsproduktSparte
      public UpsellProdukte!: ResponseBestandsproduktUpsellProdukte[]
    }
    export class Response {
      public Bestandsprodukt!: ResponseBestandsprodukt
      public Referenzen!: ResponseReferenzen
    }
  }
  export namespace GetFremdtarife {
    export class RequestLieferstelle {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public BDEWNummer?: string
      public IstGrundversorger?: boolean
      public Jahresverbrauch?: number
      public Kundenart?: string
      public Lieferstelle?: RequestLieferstelle
      public Sparte?: string
    }
    export class ResponseTarifeJahreskosten {
      public JkBrutto!: number
      public Mwst!: number
    }
    export class ResponseTarifeGp {
      public GpBruttoMonat!: number
      public GpNettoMonat!: number
      public PreisKlarschrift!: string
    }
    export class ResponseTarifeBonusStaffel {
      public WertBrutto!: number
      public WertNetto!: number
    }
    export class ResponseTarifeBonus {
      public Staffel!: ResponseTarifeBonusStaffel[]
      public Text!: string
    }
    export class ResponseTarifeAp {
      public ApBrutto!: number
      public ApNetto!: number
      public PreisKlarschrift!: string
    }
    export class ResponseTarife {
      public Anbieter!: string
      public Ap!: ResponseTarifeAp
      public BdewNr!: string
      public Bonus!: ResponseTarifeBonus[]
      public Gp!: ResponseTarifeGp
      public IstGrundversorger!: boolean
      public Jahreskosten!: ResponseTarifeJahreskosten
      public MengeBis!: number
      public MengeVon!: number
      public Name!: string
    }
    export class Response {
      public Tarife!: ResponseTarife[]
    }
  }
  export namespace GetKundenkampagnen {
    export class Request {
      public Benutzergruppen?: string[]
      public Kundenart?: string
      public KundenkampagneId?: string
      public Kundentyp?: string
      public Prozesskennzeichen?: string
      public Sparte?: string
    }
    export class ResponseKundenkampagnenVertriebspartnerTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseKundenkampagnenVertriebspartnerTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenVertriebspartnerParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerDateienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerDateienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerDateien {
      public DateiId!: ResponseKundenkampagnenVertriebspartnerDateienDateiId
      public DateiTyp!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenVertriebspartnerDateienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenVertriebspartnerContent {
      public Bezeichnung!: string
      public ContentId!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenVertriebspartnerContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenVertriebspartner {
      public Content!: ResponseKundenkampagnenVertriebspartnerContent[]
      public Dateien!: ResponseKundenkampagnenVertriebspartnerDateien[]
      public Name!: string
      public Parameter!: ResponseKundenkampagnenVertriebspartnerParameter[]
      public Texte!: ResponseKundenkampagnenVertriebspartnerTexte[]
    }
    export class ResponseKundenkampagnenTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseKundenkampagnenTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenDateienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenDateienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseKundenkampagnenDateien {
      public DateiId!: ResponseKundenkampagnenDateienDateiId
      public DateiTyp!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenDateienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseKundenkampagnenContent {
      public Bezeichnung!: string
      public ContentId!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseKundenkampagnenContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseKundenkampagnenBonussteuerungen {
      public BSCode!: string
    }
    export class ResponseKundenkampagnen {
      public Bonussteuerungen!: ResponseKundenkampagnenBonussteuerungen[]
      public Content!: ResponseKundenkampagnenContent[]
      public Dateien!: ResponseKundenkampagnenDateien[]
      public GueltigAb!: DateString
      public GueltigBis!: DateString
      public Id!: string
      public Kundenart!: string
      public Kundentyp!: string
      public Mandant!: string
      public Name!: string
      public Parameter!: ResponseKundenkampagnenParameter[]
      public Sparte!: string
      public Texte!: ResponseKundenkampagnenTexte[]
      public Vertriebspartner!: ResponseKundenkampagnenVertriebspartner
      public ZusatzDatum!: DateString
    }
    export class Response {
      public Kundenkampagnen!: ResponseKundenkampagnen[]
    }
  }
  export namespace GetLieferbeginn {
    export class Request {
      public MarktlokationsIdVorhanden?: boolean
      public Sparte?: string
      public Zaehlertyp?: string
    }
    export class Response {
      public E01!: DateString
      public E02!: DateString
      public E03!: DateString
    }
  }
  export namespace GetPreisstellung {
    export class RequestPreis {
      public AP_HT_Brutto?: number
      public AP_HT_Netto?: number
      public AP_NT_Brutto?: number
      public AP_NT_Netto?: number
      public Einmalpreis_Brutto?: number
      public Einmalpreis_Netto?: number
      public GP_Brutto_Monat?: number
      public GP_Netto_Monat?: number
    }
    export class Request {
      public Preis?: RequestPreis
      public ProduktCode?: string
    }
    export class ResponsePreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponsePreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponsePreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponsePreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponsePreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponsePreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponsePreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponsePreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponsePreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponsePreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponsePreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponsePreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponsePreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponsePreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponsePreisstaffel {
      public Einmalpreis!: ResponsePreisstaffelEinmalpreis
      public Energiemenge!: ResponsePreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponsePreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponsePreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponsePreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponsePreisstaffelVerbrauchspreis
    }
    export class Response {
      public Mwst!: number
      public Preisstaffel!: ResponsePreisstaffel[]
    }
  }
  export namespace GetProduktdatenByCode {
    export class RequestAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public AngebotVom?: DateString
      public BonusID?: string
      public BSCode?: string
      public ProduktCode?: string
      public PSTCode?: string
    }
    export class ResponseProduktVerbrauchZaehlerart {
      public Code!: string
      public Klartext!: string
    }
    export class ResponseProduktVerbrauchSpannungsebene {
      public Code!: string
      public Klartext!: string
    }
    export class ResponseProduktVerbrauch {
      public Benutzungsstunden!: number
      public Jahresverbrauch!: number
      public Spannungsebene!: ResponseProduktVerbrauchSpannungsebene
      public VerbrauchMax!: number
      public VerbrauchMin!: number
      public Zaehlerart!: ResponseProduktVerbrauchZaehlerart
    }
    export class ResponseProduktTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseProduktTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktProduktPreisgarantie {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
    }
    export class ResponseProduktProdukt {
      public Bonitaet!: number
      public HatProduktvoraussetzung!: boolean
      public IstExklusiv!: boolean
      public IstFlexpricing!: boolean
      public IstGrundversorgung!: boolean
      public IstNatur!: boolean
      public Kommentar!: string
      public Kundenart!: string
      public Lastprofil!: string
      public Mahnverfahren!: string
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseProduktProduktPreisgarantie
      public ProduktCode!: string
      public Sparte!: string
    }
    export class ResponseProduktPreisZweitarifStaffelpreisGp {
      public GpBruttoMonat!: number
      public GpNettoMonat!: number
    }
    export class ResponseProduktPreisZweitarifStaffelpreisAp {
      public ApHtBrutto!: number
      public ApHtNetto!: number
      public ApNtBrutto!: number
      public ApNtNetto!: number
    }
    export class ResponseProduktPreisZweitarifStaffelpreis {
      public Ap!: ResponseProduktPreisZweitarifStaffelpreisAp
      public Gp!: ResponseProduktPreisZweitarifStaffelpreisGp
      public MengeBis!: number
      public MengeEinheit!: string
      public MengeKlarschrift!: string
      public MengeVon!: number
      public PstCode!: string
    }
    export class ResponseProduktPreisZweitarif {
      public MwSt!: number
      public Staffelpreis!: ResponseProduktPreisZweitarifStaffelpreis[]
    }
    export class ResponseProduktPreisStaffelpreisGp {
      public GpBruttoMonat!: number
      public GpNettoMonat!: number
    }
    export class ResponseProduktPreisStaffelpreisAp {
      public ApBrutto!: number
      public ApNetto!: number
    }
    export class ResponseProduktPreisStaffelpreis {
      public Ap!: ResponseProduktPreisStaffelpreisAp
      public Gp!: ResponseProduktPreisStaffelpreisGp
      public MengeBis!: number
      public MengeEinheit!: string
      public MengeKlarschrift!: string
      public MengeVon!: number
      public PstCode!: string
    }
    export class ResponseProduktPreisNoncommStaffelpreisEinmalpreis {
      public PreisBrutto!: number
      public PreisEinheit!: string
      public PreisNetto!: number
    }
    export class ResponseProduktPreisNoncommStaffelpreis {
      public Einmalpreis!: ResponseProduktPreisNoncommStaffelpreisEinmalpreis
      public MengeBis!: number
      public MengeEinheit!: string
      public MengeKlarschrift!: string
      public MengeVon!: number
      public PstCode!: string
    }
    export class ResponseProduktPreisNoncomm {
      public MwSt!: number
      public Staffelpreis!: ResponseProduktPreisNoncommStaffelpreis[]
    }
    export class ResponseProduktPreis {
      public MwSt!: number
      public Staffelpreis!: ResponseProduktPreisStaffelpreis[]
    }
    export class ResponseProduktParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktMedienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktMedienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktMedien {
      public AltText!: string
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktMedienDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktMedienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktDokumenteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktDokumenteDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktDokumente {
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktDokumenteDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktDokumenteProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public Id!: string
      public Name!: string
      public Properties!: ResponseProduktContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseProduktBonussteuerungVertriebspartnerTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungVertriebspartnerParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerMedienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerMedienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerMedien {
      public AltText!: string
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktBonussteuerungVertriebspartnerMedienDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungVertriebspartnerMedienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerDokumenteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerDokumenteDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerDokumente {
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktBonussteuerungVertriebspartnerDokumenteDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungVertriebspartnerDokumenteProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartnerContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public Id!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungVertriebspartnerContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungVertriebspartner {
      public Content!: ResponseProduktBonussteuerungVertriebspartnerContent[]
      public Dokumente!: ResponseProduktBonussteuerungVertriebspartnerDokumente[]
      public Medien!: ResponseProduktBonussteuerungVertriebspartnerMedien[]
      public Name!: string
      public Parameter!: ResponseProduktBonussteuerungVertriebspartnerParameter[]
      public Texte!: ResponseProduktBonussteuerungVertriebspartnerTexte[]
    }
    export class ResponseProduktBonussteuerungTexteProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungTexte {
      public Hauptkategorie!: string
      public Properties!: ResponseProduktBonussteuerungTexteProperties[]
      public TextId!: string
      public TextKurz!: string
      public TextLang!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungMedienProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungMedienDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktBonussteuerungMedien {
      public AltText!: string
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktBonussteuerungMedienDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungMedienProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungContentProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public Id!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungContentProperties[]
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteStaffel {
      public BonusId!: string
      public Obergrenze!: number
      public Untergrenze!: number
      public WertBrutto!: number
      public WertNetto!: number
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktBonussteuerungBonusBonuswerteParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteInternalproperties {
      public Typ!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteEinheitMinmaxBonus {
      public Code!: string
      public Klartext!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerteEinheit {
      public Code!: string
      public Klartext!: string
    }
    export class ResponseProduktBonussteuerungBonusBonuswerte {
      public Beschreibung!: string
      public Einheit!: ResponseProduktBonussteuerungBonusBonuswerteEinheit
      public EinheitMinmaxBonus!: ResponseProduktBonussteuerungBonusBonuswerteEinheitMinmaxBonus
      public Internalproperties!: ResponseProduktBonussteuerungBonusBonuswerteInternalproperties
      public IstEuro!: boolean
      public IstFlexbonus!: boolean
      public IstSichtbar!: boolean
      public Label!: string
      public MaxBonusBrutto!: number
      public MaxBonusNetto!: number
      public MinBonusBrutto!: number
      public MinBonusNetto!: number
      public MwSt!: number
      public Nummer!: number
      public Parameter!: ResponseProduktBonussteuerungBonusBonuswerteParameter[]
      public Staffel!: ResponseProduktBonussteuerungBonusBonuswerteStaffel[]
      public Text!: string
    }
    export class ResponseProduktBonussteuerungBonus {
      public Bonuswerte!: ResponseProduktBonussteuerungBonusBonuswerte[]
    }
    export class ResponseProduktBonussteuerung {
      public Bonus!: ResponseProduktBonussteuerungBonus
      public BsCode!: string
      public Content!: ResponseProduktBonussteuerungContent[]
      public Medien!: ResponseProduktBonussteuerungMedien[]
      public Name!: string
      public Parameter!: ResponseProduktBonussteuerungParameter[]
      public Texte!: ResponseProduktBonussteuerungTexte[]
      public Vertriebspartner!: ResponseProduktBonussteuerungVertriebspartner
    }
    export class ResponseProduktAGBParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktAGBParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktAGBParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktAGBFristenZahlungsziel {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenWiderrufsfrist {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenVertragslaufzeit {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenKuendigungZum {
      public Code!: string
      public Klartext!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenKuendigungsfrist {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenFolgelaufzeit {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristenBindefrist {
      public Anzahl!: number
      public Code!: string
      public Einheit!: string
      public Label!: string
    }
    export class ResponseProduktAGBFristen {
      public Bindefrist!: ResponseProduktAGBFristenBindefrist
      public Folgelaufzeit!: ResponseProduktAGBFristenFolgelaufzeit
      public IstSchreibenVertragsbindung!: boolean
      public Kuendigungsfrist!: ResponseProduktAGBFristenKuendigungsfrist
      public KuendigungZum!: ResponseProduktAGBFristenKuendigungZum
      public MaxDatum!: DateString
      public Vertragslaufzeit!: ResponseProduktAGBFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseProduktAGBFristenWiderrufsfrist
      public Zahlungsziel!: ResponseProduktAGBFristenZahlungsziel
    }
    export class ResponseProduktAGBEZEMalus {
      public EzeArt!: string
      public EzeCode!: string
      public Preis!: number
    }
    export class ResponseProduktAGBDokumentInfoProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktAGBDokumentInfoDateiId {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseProduktAGBDokumentInfo {
      public DateiErweiterung!: string
      public DateiId!: ResponseProduktAGBDokumentInfoDateiId
      public Groesse!: number
      public Hauptkategorie!: string
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseProduktAGBDokumentInfoProperties[]
      public Unterkategorie!: string
    }
    export class ResponseProduktAGB {
      public Dokumentenversandart!: string
      public DokumentInfo!: ResponseProduktAGBDokumentInfo
      public EZEMalus!: ResponseProduktAGBEZEMalus
      public Fristen!: ResponseProduktAGBFristen
      public IstOnline!: boolean
      public Parameter!: ResponseProduktAGBParameter[]
      public Tarifmitnahme!: string
    }
    export class ResponseProdukt {
      public AGB!: ResponseProduktAGB
      public Bonussteuerung!: ResponseProduktBonussteuerung
      public Content!: ResponseProduktContent[]
      public Dokumente!: ResponseProduktDokumente[]
      public Medien!: ResponseProduktMedien[]
      public Parameter!: ResponseProduktParameter[]
      public Preis!: ResponseProduktPreis
      public PreisNoncomm!: ResponseProduktPreisNoncomm
      public PreisZweitarif!: ResponseProduktPreisZweitarif
      public Produkt!: ResponseProduktProdukt
      public Texte!: ResponseProduktTexte[]
      public Verbrauch!: ResponseProduktVerbrauch
    }
    export class Response {
      public Produkt!: ResponseProdukt
    }
  }
  export namespace GetProduktdatenByCodeKosten {
    export class RequestKostenVorversorgerVerbrauchspreis {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGrundpreisMonat {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGrundpreisJahr {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGesamtkosten {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorger {
      public Gesamtkosten?: RequestKostenVorversorgerGesamtkosten
      public GrundpreisJahr?: RequestKostenVorversorgerGrundpreisJahr
      public GrundpreisMonat?: RequestKostenVorversorgerGrundpreisMonat
      public Verbrauchspreis?: RequestKostenVorversorgerVerbrauchspreis
    }
    export class RequestAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public AngebotVom?: DateString
      public BonusID?: string
      public BSCode?: string
      public Jahresverbrauch?: number
      public KostenVorversorger?: RequestKostenVorversorger
      public ProduktCode?: string
      public PSTCode?: string
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseProduktUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProduktSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktPreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktPreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseProduktPreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseProduktPreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseProduktPreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktPreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseProduktPreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseProduktPreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseProduktPreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseProduktPreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseProduktPreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseProduktPreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktKostenvergleich {
      public Differenz!: ResponseProduktKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseProduktKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseProduktKostenBonusAngerechnet
      public Jahreskosten!: ResponseProduktKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseProduktInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProduktFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProduktFristen {
      public Bindefrist!: ResponseProduktFristenBindefrist
      public Folgelaufzeit!: ResponseProduktFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseProduktFristenKuendigungsfrist
      public KuendigungZum!: ResponseProduktFristenKuendigungZum
      public Vertragslaufzeit!: ResponseProduktFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseProduktFristenWiderrufsfrist
      public Zahlungsziel!: ResponseProduktFristenZahlungsziel
    }
    export class ResponseProduktFelder { }
    export class ResponseProduktCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProduktBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProduktBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseProduktBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseProduktBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProduktBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseProduktBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseProduktBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseProduktBonussteuerung {
      public Bonuswerte!: ResponseProduktBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseProduktBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseProdukt {
      public Bonussteuerung!: ResponseProduktBonussteuerung
      public CrossSellProdukte!: ResponseProduktCrossSellProdukte[]
      public Felder!: ResponseProduktFelder
      public Fristen!: ResponseProduktFristen
      public Inhalte!: ResponseProduktInhalte
      public Kosten!: ResponseProduktKosten[]
      public Kostenvergleich!: ResponseProduktKostenvergleich[]
      public Kundenart!: ResponseProduktKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseProduktPreisgarantie
      public Preiszeitscheiben!: ResponseProduktPreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseProduktSparte
      public UpsellProdukte!: ResponseProduktUpsellProdukte[]
    }
    export class Response {
      public Produkt!: ResponseProdukt
      public Referenzen!: ResponseReferenzen
    }
  }
  export namespace GetProdukte {
    export class RequestKostenVorversorgerVerbrauchspreis {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGrundpreisMonat {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGrundpreisJahr {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorgerGesamtkosten {
      public Brutto?: number
      public Netto?: number
    }
    export class RequestKostenVorversorger {
      public Gesamtkosten?: RequestKostenVorversorgerGesamtkosten
      public GrundpreisJahr?: RequestKostenVorversorgerGrundpreisJahr
      public GrundpreisMonat?: RequestKostenVorversorgerGrundpreisMonat
      public Verbrauchspreis?: RequestKostenVorversorgerVerbrauchspreis
    }
    export class RequestFelder { }
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public BSCode?: string[]
      public Felder?: RequestFelder
      public GP?: string
      public Jahresverbrauch?: number
      public KostenVorversorger?: RequestKostenVorversorger
      public Kundenart?: string
      public ProduktCode?: string[]
      public Sparte?: string
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseProdukteUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProdukteSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseProduktePreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseProduktePreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseProduktePreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseProduktePreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseProduktePreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseProduktePreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKostenvergleich {
      public Differenz!: ResponseProdukteKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseProdukteKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseProdukteKostenBonusAngerechnet
      public Jahreskosten!: ResponseProdukteKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseProdukteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristen {
      public Bindefrist!: ResponseProdukteFristenBindefrist
      public Folgelaufzeit!: ResponseProdukteFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseProdukteFristenKuendigungsfrist
      public KuendigungZum!: ResponseProdukteFristenKuendigungZum
      public Vertragslaufzeit!: ResponseProdukteFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseProdukteFristenWiderrufsfrist
      public Zahlungsziel!: ResponseProdukteFristenZahlungsziel
    }
    export class ResponseProdukteFelder { }
    export class ResponseProdukteCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProdukteBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseProdukteBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseProdukteBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseProdukteBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseProdukteBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseProdukteBonussteuerung {
      public Bonuswerte!: ResponseProdukteBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseProdukteBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseProdukte {
      public Bonussteuerung!: ResponseProdukteBonussteuerung
      public CrossSellProdukte!: ResponseProdukteCrossSellProdukte[]
      public Felder!: ResponseProdukteFelder
      public Fristen!: ResponseProdukteFristen
      public Inhalte!: ResponseProdukteInhalte
      public Kosten!: ResponseProdukteKosten[]
      public Kostenvergleich!: ResponseProdukteKostenvergleich[]
      public Kundenart!: ResponseProdukteKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseProduktePreisgarantie
      public Preiszeitscheiben!: ResponseProduktePreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseProdukteSparte
      public UpsellProdukte!: ResponseProdukteUpsellProdukte[]
    }
    export class Response {
      public Produkte!: ResponseProdukte[]
      public Referenzen!: ResponseReferenzen
    }
  }
  export namespace GetProduktinformation {
    export class Request {
      public Kundentyp?: string
      public Prozesskennzeichen?: string
      public QuellProduktCode?: string
      public VK?: string
      public ZielProduktCode?: string
    }
    export class Response {
      public ProduktCodes!: string[]
    }
  }
  export namespace GetProduktparameter {
    export class Request {
      public ProduktCodes?: string[]
    }
    export class ResponseProduktParameterParameterProperties {
      public IsLazy!: boolean
      public Name!: string
      public Typ!: string
      public Wert!: string
    }
    export class ResponseProduktParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseProduktParameterParameterProperties[]
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseProduktParameter {
      public Parameter!: ResponseProduktParameterParameter[]
      public ProduktCode!: string
    }
    export class Response {
      public ProduktParameter!: ResponseProduktParameter[]
    }
  }
  export namespace GetUmzugsprodukte {
    export class RequestWerte { }
    export class RequestNeueAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public AngebotVom?: DateString
      public Auszugsdatum?: DateString
      public BSCodes?: string[]
      public Einzugsdatum?: DateString
      public Jahresverbrauch?: number
      public NeueAdresse?: RequestNeueAdresse
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseUmzugsprodukteUmzugsdaten {
      public Anzeigetext!: string
      public Bonusrueckholung!: boolean
      public Bonusverlust!: boolean
      public Typ!: string
      public TypLabel!: string
      public Umzugsdatum!: DateString
    }
    export class ResponseUmzugsprodukteProduktDetailsUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseUmzugsprodukteProduktDetailsPreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseUmzugsprodukteProduktDetailsPreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsKostenvergleich {
      public Differenz!: ResponseUmzugsprodukteProduktDetailsKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseUmzugsprodukteProduktDetailsKostenBonusAngerechnet
      public Jahreskosten!: ResponseUmzugsprodukteProduktDetailsKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsFristen {
      public Bindefrist!: ResponseUmzugsprodukteProduktDetailsFristenBindefrist
      public Folgelaufzeit!: ResponseUmzugsprodukteProduktDetailsFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseUmzugsprodukteProduktDetailsFristenKuendigungsfrist
      public KuendigungZum!: ResponseUmzugsprodukteProduktDetailsFristenKuendigungZum
      public Vertragslaufzeit!: ResponseUmzugsprodukteProduktDetailsFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseUmzugsprodukteProduktDetailsFristenWiderrufsfrist
      public Zahlungsziel!: ResponseUmzugsprodukteProduktDetailsFristenZahlungsziel
    }
    export class ResponseUmzugsprodukteProduktDetailsFelder { }
    export class ResponseUmzugsprodukteProduktDetailsCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseUmzugsprodukteProduktDetailsBonussteuerung {
      public Bonuswerte!: ResponseUmzugsprodukteProduktDetailsBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseUmzugsprodukteProduktDetailsBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseUmzugsprodukteProduktDetails {
      public Bonussteuerung!: ResponseUmzugsprodukteProduktDetailsBonussteuerung
      public CrossSellProdukte!: ResponseUmzugsprodukteProduktDetailsCrossSellProdukte[]
      public Felder!: ResponseUmzugsprodukteProduktDetailsFelder
      public Fristen!: ResponseUmzugsprodukteProduktDetailsFristen
      public Inhalte!: ResponseUmzugsprodukteProduktDetailsInhalte
      public Kosten!: ResponseUmzugsprodukteProduktDetailsKosten[]
      public Kostenvergleich!: ResponseUmzugsprodukteProduktDetailsKostenvergleich[]
      public Kundenart!: ResponseUmzugsprodukteProduktDetailsKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseUmzugsprodukteProduktDetailsPreisgarantie
      public Preiszeitscheiben!: ResponseUmzugsprodukteProduktDetailsPreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseUmzugsprodukteProduktDetailsSparte
      public UpsellProdukte!: ResponseUmzugsprodukteProduktDetailsUpsellProdukte[]
    }
    export class ResponseUmzugsprodukte {
      public ProduktDetails!: ResponseUmzugsprodukteProduktDetails
      public Umzugsdaten!: ResponseUmzugsprodukteUmzugsdaten[]
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseAusgangsproduktUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseAusgangsproduktSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAusgangsproduktPreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseAusgangsproduktPreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseAusgangsproduktPreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseAusgangsproduktPreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseAusgangsproduktPreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseAusgangsproduktPreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseAusgangsproduktKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktKostenvergleich {
      public Differenz!: ResponseAusgangsproduktKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseAusgangsproduktKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseAusgangsproduktKostenBonusAngerechnet
      public Jahreskosten!: ResponseAusgangsproduktKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseAusgangsproduktInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseAusgangsproduktFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseAusgangsproduktFristen {
      public Bindefrist!: ResponseAusgangsproduktFristenBindefrist
      public Folgelaufzeit!: ResponseAusgangsproduktFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseAusgangsproduktFristenKuendigungsfrist
      public KuendigungZum!: ResponseAusgangsproduktFristenKuendigungZum
      public Vertragslaufzeit!: ResponseAusgangsproduktFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseAusgangsproduktFristenWiderrufsfrist
      public Zahlungsziel!: ResponseAusgangsproduktFristenZahlungsziel
    }
    export class ResponseAusgangsproduktFelder { }
    export class ResponseAusgangsproduktCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseAusgangsproduktBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseAusgangsproduktBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseAusgangsproduktBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseAusgangsproduktBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseAusgangsproduktBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseAusgangsproduktBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseAusgangsproduktBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseAusgangsproduktBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseAusgangsproduktBonussteuerung {
      public Bonuswerte!: ResponseAusgangsproduktBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseAusgangsproduktBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseAusgangsprodukt {
      public Bonussteuerung!: ResponseAusgangsproduktBonussteuerung
      public CrossSellProdukte!: ResponseAusgangsproduktCrossSellProdukte[]
      public Felder!: ResponseAusgangsproduktFelder
      public Fristen!: ResponseAusgangsproduktFristen
      public Inhalte!: ResponseAusgangsproduktInhalte
      public Kosten!: ResponseAusgangsproduktKosten[]
      public Kostenvergleich!: ResponseAusgangsproduktKostenvergleich[]
      public Kundenart!: ResponseAusgangsproduktKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseAusgangsproduktPreisgarantie
      public Preiszeitscheiben!: ResponseAusgangsproduktPreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseAusgangsproduktSparte
      public UpsellProdukte!: ResponseAusgangsproduktUpsellProdukte[]
    }
    export class Response {
      public Ausgangsprodukt!: ResponseAusgangsprodukt
      public Referenzen!: ResponseReferenzen
      public Umzugsprodukte!: ResponseUmzugsprodukte[]
    }
  }
  export namespace GetWechselprodukte {
    export class RequestFelder { }
    export class Request {
      public BSCode?: string[]
      public Felder?: RequestFelder
      public ProduktCode?: string[]
      public VK?: string
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseProdukteWechseldatenTyp {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteWechseldaten {
      public Anzeigetext!: string
      public Bonusrueckholung!: boolean
      public Bonusverlust!: boolean
      public Typ!: ResponseProdukteWechseldatenTyp
      public Wechseldatum!: DateString
    }
    export class ResponseProdukteUpsellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProdukteSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseProduktePreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseProduktePreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseProduktePreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseProduktePreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseProduktePreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseProduktePreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseProduktePreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseProduktePreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseProduktePreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseProduktePreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseProdukteKostenvergleichDifferenz {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKostenvergleich {
      public Differenz!: ResponseProdukteKostenvergleichDifferenz
      public Zeitraum!: string
    }
    export class ResponseProdukteKostenJahreskosten {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKostenBonusAngerechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteKosten {
      public Abschlag!: number
      public BonusAngerechnet!: ResponseProdukteKostenBonusAngerechnet
      public Jahreskosten!: ResponseProdukteKostenJahreskosten
      public Zeitraum!: string
    }
    export class ResponseProdukteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseProdukteFristen {
      public Bindefrist!: ResponseProdukteFristenBindefrist
      public Folgelaufzeit!: ResponseProdukteFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseProdukteFristenKuendigungsfrist
      public KuendigungZum!: ResponseProdukteFristenKuendigungZum
      public Vertragslaufzeit!: ResponseProdukteFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseProdukteFristenWiderrufsfrist
      public Zahlungsziel!: ResponseProdukteFristenZahlungsziel
    }
    export class ResponseProdukteFelder { }
    export class ResponseProdukteCrossSellProdukte {
      public ProduktCode!: string
      public ProduktGruppe!: string
    }
    export class ResponseProdukteBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseProdukteBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseProdukteBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseProdukteBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseProdukteBonussteuerungBonuswerte {
      public Einheit!: string
      public Inhalte!: ResponseProdukteBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseProdukteBonussteuerungBonuswerteStaffel[]
    }
    export class ResponseProdukteBonussteuerung {
      public Bonuswerte!: ResponseProdukteBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseProdukteBonussteuerungInhalte
      public Name!: string
    }
    export class ResponseProdukte {
      public Bonussteuerung!: ResponseProdukteBonussteuerung
      public CrossSellProdukte!: ResponseProdukteCrossSellProdukte[]
      public Felder!: ResponseProdukteFelder
      public Fristen!: ResponseProdukteFristen
      public Inhalte!: ResponseProdukteInhalte
      public Kosten!: ResponseProdukteKosten[]
      public Kostenvergleich!: ResponseProdukteKostenvergleich[]
      public Kundenart!: ResponseProdukteKundenart
      public MaxDatum!: DateString
      public MinDatum!: DateString
      public Name!: string
      public Preisgarantie!: ResponseProduktePreisgarantie
      public Preiszeitscheiben!: ResponseProduktePreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktTyp!: string
      public Sparte!: ResponseProdukteSparte
      public UpsellProdukte!: ResponseProdukteUpsellProdukte[]
      public Wechseldaten!: ResponseProdukteWechseldaten[]
    }
    export class Response {
      public Produkte!: ResponseProdukte[]
      public Referenzen!: ResponseReferenzen
    }
  }
}

export namespace Prozesse {
  export namespace CreateProzessinformation {
    export class RequestProzessinformationWerteliste { }
    export class RequestProzessinformation {
      public GueltigAb?: Date
      public GueltigBis?: Date
      public Prozesskennzeichen?: string
      public Referenz?: string
      public Werteliste?: RequestProzessinformationWerteliste
    }
    export class Request {
      public Prozessinformation?: RequestProzessinformation
    }
    export class Response { }
  }
  export namespace DeleteProzessinformation {
    export class RequestWerte { }
    export class Request {
      public GueltigBis?: Date
      public Prozesskennzeichen?: string
      public Referenz?: string
      public Werte?: RequestWerte
    }
    export class Response { }
  }
  export namespace GetProzesse {
    export class RequestWerte { }
    export class Request {
      public GP?: string[]
      public Prozesse?: string
      public Username?: string
      public VK?: string[]
      public Werte?: RequestWerte
    }
    export class ResponseVKVK {
      public AnzeigeText!: string
      public ReferenzID!: string
    }
    export class ResponseVKProzesseWerte { }
    export class ResponseVKProzesse {
      public Code!: string
      public FachlicheBeschreibung!: string
      public Prozess!: string
      public Status!: boolean
      public TechnischeBeschreibung!: string
      public Werte!: ResponseVKProzesseWerte
    }
    export class ResponseVK {
      public Prozesse!: ResponseVKProzesse[]
      public VK!: ResponseVKVK
    }
    export class ResponseUsernameProzesseWerte { }
    export class ResponseUsernameProzesse {
      public Code!: string
      public FachlicheBeschreibung!: string
      public Prozess!: string
      public Status!: boolean
      public TechnischeBeschreibung!: string
      public Werte!: ResponseUsernameProzesseWerte
    }
    export class ResponseUsername {
      public Prozesse!: ResponseUsernameProzesse[]
      public Username!: string
    }
    export class ResponseGPProzesseWerte { }
    export class ResponseGPProzesse {
      public Code!: string
      public FachlicheBeschreibung!: string
      public Prozess!: string
      public Status!: boolean
      public TechnischeBeschreibung!: string
      public Werte!: ResponseGPProzesseWerte
    }
    export class ResponseGPGP {
      public AnzeigeText!: string
      public ReferenzID!: string
    }
    export class ResponseGP {
      public GP!: ResponseGPGP
      public Prozesse!: ResponseGPProzesse[]
    }
    export class Response {
      public GP!: ResponseGP[]
      public InterneZeit!: Date
      public Username!: ResponseUsername
      public VK!: ResponseVK[]
    }
  }
  export namespace GetProzessinformation {
    export class RequestWerte { }
    export class Request {
      public GP?: string
      public Prozesskennzeichen?: string[]
      public Referenz?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseErgebnisseWerteliste { }
    export class ResponseErgebnisse {
      public GueltigAb!: Date
      public GueltigBis!: Date
      public Prozesskennzeichen!: string
      public Referenz!: string
      public Werteliste!: ResponseErgebnisseWerteliste
    }
    export class Response {
      public Ergebnisse!: ResponseErgebnisse[]
    }
  }
  export namespace GetProzessparameter {
    export class Request {
      public Prozessparameter?: string
    }
    export class ResponseProzessparameter {
      public Name!: string
      public Wert!: string
    }
    export class Response {
      public Prozessparameter!: ResponseProzessparameter
    }
  }
}

export namespace Report {
  export namespace GetReport {
    export class RequestWerte { }
    export class Request {
      public DatumBis?: DateString
      public DatumVon?: DateString
      public ReportName?: string
      public Username?: string
      public Werte?: RequestWerte
    }
    export class ResponseSpaltensortierungen {
      public Richtung!: string
      public SpaltenID!: string
    }
    export class ResponseSpalteninformationenProperties { }
    export class ResponseSpalteninformationen {
      public Anzeigename!: string
      public Datentyp!: string
      public NichtAnzeigen!: boolean
      public Properties!: ResponseSpalteninformationenProperties
      public SpaltenID!: string
    }
    export class ResponseReportinformationProperties { }
    export class ResponseReportinformation {
      public Anzeigetext!: string
      public Name!: string
      public Properties!: ResponseReportinformationProperties
    }
    export class ResponseDaten { }
    export class Response {
      public Daten!: ResponseDaten[]
      public Reportinformation!: ResponseReportinformation
      public Spalteninformationen!: ResponseSpalteninformationen[]
      public Spaltensortierungen!: ResponseSpaltensortierungen[]
    }
  }
  export namespace GetReportliste {
    export class Request {
      public Typ?: string
      public Username?: string
    }
    export class ResponseReportsProperties { }
    export class ResponseReports {
      public Anzeigetext!: string
      public Name!: string
      public Properties!: ResponseReportsProperties
    }
    export class Response {
      public Reports!: ResponseReports[]
    }
  }
}

export namespace Validierung {
  export namespace CheckAdresse {
    export class Request {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class ResponseZustellbarkeit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseStatusVerarbeitung {
      public Anzeigetext!: string
      public Code!: string
    }
    export class Response {
      public GradUebereinstimmung!: number
      public Hausnummer!: string
      public Ort!: string
      public Ortsteil!: string
      public PLZ!: string
      public StatusVerarbeitung!: ResponseStatusVerarbeitung
      public Strasse!: string
      public Zustellbarkeit!: ResponseZustellbarkeit
    }
  }
  export namespace CheckDatei {
    export class Request {
      public Daten?: string
      public Name?: string
    }
  }
  export namespace CheckFelder {
    export class RequestFelder { }
    export class Request {
      public Felder?: RequestFelder
    }
    export class ResponseWerte { }
    export class ResponseKorrigierteFelder { }
    export class Response {
      public KorrigierteFelder!: ResponseKorrigierteFelder
      public Werte!: ResponseWerte
    }
  }
  export namespace CheckJobStatus {
    export class Request {
      public Name?: string
    }
    export class Response {
      public Active!: boolean
    }
  }
}

export namespace Vertrag {
  export namespace CreateKuendigung {
    export class RequestWerte { }
    export class RequestNeueLieferadresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public Datum?: DateString
      public Dokumente?: RequestDokumente[]
      public Grund?: string
      public NeueLieferadresse?: RequestNeueLieferadresse
      public VK?: string
      public Werte?: RequestWerte
      public Zaehlerstand?: number
    }
    export class Response { }
  }
  export namespace CreateUmzug {
    export class RequestWerte { }
    export class RequestEinzugOptsPraeferenz {
      public Name?: string
      public Status?: boolean
    }
    export class RequestEinzugOptsEinwilligungen {
      public Kommunikationsweg?: string
      public Kontaktdaten?: string
      public Sparte?: string
      public Status?: string
    }
    export class RequestEinzugOpts {
      public Einwilligungen?: RequestEinzugOptsEinwilligungen[]
      public Praeferenz?: RequestEinzugOptsPraeferenz[]
      public RobinsonFlag?: boolean
    }
    export class RequestEinzugNeueZahlungsinformationSEPA {
      public BIC?: string
      public Datenherkunft?: string
      public IBAN?: string
      public Kontoinhaber?: string
    }
    export class RequestEinzugNeueZahlungsinformationOnlineBezahldienst {
      public Benutzername?: string
      public Dienstleister?: string
      public Token?: string
    }
    export class RequestEinzugNeueZahlungsinformation {
      public OnlineBezahldienst?: RequestEinzugNeueZahlungsinformationOnlineBezahldienst
      public SEPA?: RequestEinzugNeueZahlungsinformationSEPA
      public Zahlungsrichtung?: string
    }
    export class RequestEinzugNeueRechnungsadresseAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Postfach?: string
      public Strasse?: string
    }
    export class RequestEinzugNeueRechnungsadresse {
      public Adresse?: RequestEinzugNeueRechnungsadresseAdresse
      public Anrede?: string
      public Name1_Nachname?: string
      public Name2_Vorname?: string
    }
    export class RequestEinzugNeueMarktlokationLieferadresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class RequestEinzugNeueMarktlokation {
      public Lieferadresse?: RequestEinzugNeueMarktlokationLieferadresse
      public MarktlokationsID?: string
      public Zaehlernummer?: string
      public Zaehlpunktbezeichnung?: string
    }
    export class RequestEinzugNeueKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestEinzugEinzugszaehlerstaende {
      public Zaehlerstand?: number
      public Zaehlwerksart?: string
    }
    export class RequestEinzug {
      public AngebotGueltigAb?: DateString
      public AngebotGueltigBis?: DateString
      public BonusId?: string
      public BSCode?: string
      public Einzugszaehlerstaende?: RequestEinzugEinzugszaehlerstaende[]
      public GewuenschterLieferbeginn?: DateString
      public IstProduktmitnahme?: boolean
      public NeueKontaktdaten?: RequestEinzugNeueKontaktdaten
      public NeueMarktlokation?: RequestEinzugNeueMarktlokation
      public NeueRechnungsadresse?: RequestEinzugNeueRechnungsadresse
      public NeueZahlungsinformation?: RequestEinzugNeueZahlungsinformation
      public Opts?: RequestEinzugOpts
      public ProduktCode?: string
      public PSTCode?: string
      public Transaktionsgrund?: string
      public VertriebspartnerName?: string
    }
    export class RequestDokumenteDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestDokumente {
      public DateiId?: RequestDokumenteDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class RequestAuszug {
      public Auszugsdatum?: DateString
      public Transaktionsgrund?: string
      public VK?: string
    }
    export class Request {
      public Auszug?: RequestAuszug
      public Dokumente?: RequestDokumente[]
      public Einzug?: RequestEinzug
      public Werte?: RequestWerte
    }
    export class Response {
      public Auftragscode!: string
    }
  }
  export namespace CreateVollmacht {
    export class RequestAdresse {
      public Hausnummer?: string
      public Ort?: string
      public PLZ?: string
      public Strasse?: string
    }
    export class Request {
      public Adresse?: RequestAdresse
      public Anrede?: string
      public GP_GUID?: string
      public Nachname?: string
      public Titel?: string
      public VK_GUID?: string
      public Vorname?: string
    }
    export class Response {
      public ArbeitsauftragID!: string
    }
  }
  export namespace CreateWiderruf {
    export class RequestWerte { }
    export class Request {
      public Datum?: DateString
      public Grund?: string
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response { }
  }
  export namespace GetForderungen {
    export class RequestWerte { }
    export class Request {
      public VK?: string
      public Werte?: RequestWerte
    }
    export class ResponseKontoUebersichtTyp {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseKontoUebersichtStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseKontoUebersicht {
      public AusgeglichenerBetrag!: number
      public Buchungsdatum!: DateString
      public Forderungsbetrag!: number
      public Status!: ResponseKontoUebersichtStatus
      public Typ!: ResponseKontoUebersichtTyp
    }
    export class Response {
      public FaelligerForderungsbetrag!: number
      public FaelligZum!: DateString
      public KontoUebersicht!: ResponseKontoUebersicht[]
    }
  }
  export namespace GetLastgang {
    export class Request {
      public Bis?: string
      public Typ?: string
      public VK?: string
      public Von?: string
    }
    export class ResponseTageWerte { }
    export class ResponseTage {
      public Datum!: Date
      public DisplayTyp!: string
      public kWh!: number
      public PAvg!: number
      public PMax!: number
      public PMin!: number
      public Werte!: ResponseTageWerte
    }
    export class Response {
      public Bis!: Date
      public kWh!: number
      public PAvg!: number
      public PMax!: number
      public PMin!: number
      public Tage!: ResponseTage[]
      public Von!: Date
    }
  }
  export namespace GetLastgangKosten {
    export class Request {
      public Bis?: string
      public Typ?: string
      public VK?: string
      public Von?: string
    }
    export class ResponseTageWerte { 
      public PreisNetto!: number
      public PreisBrutto!: number
      public Arbeit!: number
      public KostenNetto!: number
      public KostenBrutto!: number
    }
    export class ResponseTage {
      public Datum!: Date
      public DisplayTyp!: string
      public kWh!: number
      public PAvg!: number
      public PMax!: number
      public PMin!: number
      public PreisMengengewichtet!: number
      public PreisMengengewichtetBrutto!: number
      public Werte!: Record<string, ResponseTageWerte>
    }
    export class Response {
      public Bis!: Date
      public kWh!: number
      public PAvg!: number
      public PMax!: number
      public PMin!: number
      public PreisMengengewichtet!: number
      public PreisMengengewichtetBrutto!: number
      public SummeKostenNetto!: number
      public SummeKostenBrutto!: number
      public Arbeit!: number
      public Tage!: ResponseTage[]
      public Von!: Date
    }
  }
  export namespace GetMoeglichesVertragsende {
    export class Request {
      public VK?: string
    }
    export class ResponseMoeglicheVertragsenden {
      public Beendigungsgrund!: string
      public KuendigungMoeglichBis!: DateString
      public KuendigungMoeglichMin!: DateString
      public Transaktionsgrund!: string
    }
    export class Response {
      public MoeglicheVertragsenden!: ResponseMoeglicheVertragsenden[]
    }
  }
  export namespace GetVertraege {
    export class RequestWerte { }
    export class Request {
      public GP?: string[]
      public VK?: string[]
      public Werte?: RequestWerte
    }
    export class ResponseReferenzenTexteTextProperties { }
    export class ResponseReferenzenTexteText {
      public Hauptkategorie!: string
      public ID!: string
      public Kurztext!: string
      public Langtext!: string
      public Properties!: ResponseReferenzenTexteTextProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenTexte {
      public ReferenzID!: string
      public Text!: ResponseReferenzenTexteText
    }
    export class ResponseReferenzenSteuerelementeSteuerelementProperties { }
    export class ResponseReferenzenSteuerelementeSteuerelement {
      public CheckFunktion!: string
      public DropdownVorschlagswerte!: string
      public Hauptkategorie!: string
      public Id!: string
      public KeyValue!: string
      public Label!: string
      public MaxDatum!: string
      public MinDatum!: string
      public NurVorhandeneVorschlagswerteZulassen!: string
      public Pflichtfeld!: string
      public Properties!: ResponseReferenzenSteuerelementeSteuerelementProperties
      public Regex!: string
      public Style!: string
      public Tooltip!: string
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenSteuerelemente {
      public ReferenzID!: string
      public Steuerelement!: ResponseReferenzenSteuerelementeSteuerelement
    }
    export class ResponseReferenzenParameterParameterProperties { }
    export class ResponseReferenzenParameterParameter {
      public Datentyp!: string
      public Hauptkategorie!: string
      public Name!: string
      public Properties!: ResponseReferenzenParameterParameterProperties
      public Unterkategorie!: string
      public Wert!: string
    }
    export class ResponseReferenzenParameter {
      public Parameter!: ResponseReferenzenParameterParameter
      public ReferenzID!: string
    }
    export class ResponseReferenzenMedienMediumProperties { }
    export class ResponseReferenzenMedienMediumID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenMedienMedium {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenMedienMediumID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenMedienMediumProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenMedien {
      public Medium!: ResponseReferenzenMedienMedium
      public ReferenzID!: string
    }
    export class ResponseReferenzenDokumenteDokumentProperties { }
    export class ResponseReferenzenDokumenteDokumentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenDokumenteDokument {
      public Dateierweiterung!: string
      public Groesse!: number
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenDokumenteDokumentID
      public Kurztext!: string
      public Linkname!: string
      public Name!: string
      public Properties!: ResponseReferenzenDokumenteDokumentProperties
      public Unterkategorie!: string
    }
    export class ResponseReferenzenDokumente {
      public Dokument!: ResponseReferenzenDokumenteDokument
      public ReferenzID!: string
    }
    export class ResponseReferenzenContentContentProperties { }
    export class ResponseReferenzenContentContentID {
      public DateiId!: string
      public SystemId!: string
    }
    export class ResponseReferenzenContentContent {
      public Bezeichnung!: string
      public Hauptkategorie!: string
      public ID!: ResponseReferenzenContentContentID
      public Name!: string
      public Properties!: ResponseReferenzenContentContentProperties
      public Typ!: string
      public Unterkategorie!: string
    }
    export class ResponseReferenzenContent {
      public Content!: ResponseReferenzenContentContent
      public ReferenzID!: string
    }
    export class ResponseReferenzen {
      public Content!: ResponseReferenzenContent[]
      public Dokumente!: ResponseReferenzenDokumente[]
      public Medien!: ResponseReferenzenMedien[]
      public Parameter!: ResponseReferenzenParameter[]
      public Steuerelemente!: ResponseReferenzenSteuerelemente[]
      public Texte!: ResponseReferenzenTexte[]
    }
    export class ResponseGPZweiterVertragspartner {
      public Nachname!: string
      public Vorname!: string
    }
    export class ResponseGPWerte { }
    export class ResponseGPVertraegeZaehlerdatenZaehlerart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeZaehlerdatenOffenerAbleseauftragAblesegrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeZaehlerdatenOffenerAbleseauftrag {
      public AblesedatumSoll!: DateString
      public Ablesegrund!: ResponseGPVertraegeZaehlerdatenOffenerAbleseauftragAblesegrund
      public ZuordnungsdatumMax!: DateString
      public ZuordnungsdatumMin!: DateString
    }
    export class ResponseGPVertraegeZaehlerdaten {
      public IstRLM!: boolean
      public Jahresverbrauch!: number
      public OffenerAbleseauftrag!: ResponseGPVertraegeZaehlerdatenOffenerAbleseauftrag
      public Zaehlerart!: ResponseGPVertraegeZaehlerdatenZaehlerart
      public Zaehlernummer!: string
    }
    export class ResponseGPVertraegeWerte { }
    export class ResponseGPVertraegeVK {
      public Anzeigetext!: string
      public ReferenzID!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenWerte {
      public ENDE_PREISGARANTIE?: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenZeitscheibenGrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreis {
      public Verbrauchspreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis {
      public Verbrauchspreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreis
      public VerbrauchspreisHT!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisHT
      public VerbrauchspreisNT!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreisVerbrauchspreisNT
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVario {
      public Einmalpreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioEinmalpreis
      public GrundpreisJahr!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisJahr
      public GrundpreisMonat!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioGrundpreisMonat
      public PSTCode!: string
      public Verbrauchspreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVarioVerbrauchspreis
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelGrundpreisMonat {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelGrundpreisJahr {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelEnergiemenge {
      public Anzeigetext!: string
      public Bis!: number
      public Einheit!: string
      public Von!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelEinmalpreis {
      public Brutto!: number
      public Einheit!: string
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffel {
      public Einmalpreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelEinmalpreis
      public Energiemenge!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelEnergiemenge
      public GrundpreisJahr!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelGrundpreisJahr
      public GrundpreisMonat!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelGrundpreisMonat
      public MaxPreisVario!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelMaxPreisVario
      public PSTCode!: string
      public RelevanteStaffel!: boolean
      public Verbrauchspreis!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffelVerbrauchspreis
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreiszeitscheiben {
      public MwSt!: number
      public Preisstaffel!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenPreisstaffel[]
      public PreiszeitscheibeBis!: DateString
      public PreiszeitscheibeVon!: DateString
      public RelevanteZeitscheibe!: boolean
      public ZeitscheibenGrund!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheibenZeitscheibenGrund
    }
    export class ResponseGPVertraegeVertragszeitscheibenPreisgarantie {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenZahlungsziel {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenWiderrufsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenVertragslaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenKuendigungZum {
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenKuendigungsfrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenFolgelaufzeit {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristenBindefrist {
      public Anzahl!: number
      public Anzeigetext!: string
      public Code!: string
      public Label!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenFristen {
      public Bindefrist!: ResponseGPVertraegeVertragszeitscheibenFristenBindefrist
      public Folgelaufzeit!: ResponseGPVertraegeVertragszeitscheibenFristenFolgelaufzeit
      public Kuendigungsfrist!: ResponseGPVertraegeVertragszeitscheibenFristenKuendigungsfrist
      public KuendigungZum!: ResponseGPVertraegeVertragszeitscheibenFristenKuendigungZum
      public Vertragslaufzeit!: ResponseGPVertraegeVertragszeitscheibenFristenVertragslaufzeit
      public Widerrufsfrist!: ResponseGPVertraegeVertragszeitscheibenFristenWiderrufsfrist
      public Zahlungsziel!: ResponseGPVertraegeVertragszeitscheibenFristenZahlungsziel
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteVerrechnet {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStaffelBonuswert {
      public Brutto!: number
      public Netto!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStaffel {
      public BonusID!: string
      public Bonuswert!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStaffelBonuswert
      public RelevanteStaffel!: boolean
      public StaffelBis!: number
      public StaffelVon!: number
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteInhalte {
      public ContentReferenzIds!: string[]
      public DokumenteReferenzIds!: string[]
      public MedienReferenzIds!: string[]
      public ParameterReferenzIds!: string[]
      public SteuerelementeReferenzIds!: string[]
      public TextReferenzIds!: string[]
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteEinheit {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerte {
      public Einheit!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteEinheit
      public Inhalte!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteInhalte
      public MwSt!: number
      public Name!: string
      public Staffel!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStaffel[]
      public Status!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteStatus
      public Verrechnet!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerteVerrechnet
      public VerrechnetMit!: string
      public Verrechnungsdatum!: DateString
    }
    export class ResponseGPVertraegeVertragszeitscheibenBonussteuerung {
      public Bonuswerte!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungBonuswerte[]
      public BSCode!: string
      public Inhalte!: ResponseGPVertraegeVertragszeitscheibenBonussteuerungInhalte
    }
    export class ResponseGPVertraegeVertragszeitscheiben {
      public Bonussteuerung!: ResponseGPVertraegeVertragszeitscheibenBonussteuerung
      public Fristen!: ResponseGPVertraegeVertragszeitscheibenFristen
      public Inhalte!: ResponseGPVertraegeVertragszeitscheibenInhalte
      public Preisgarantie!: ResponseGPVertraegeVertragszeitscheibenPreisgarantie
      public Preiszeitscheiben!: ResponseGPVertraegeVertragszeitscheibenPreiszeitscheiben[]
      public ProduktBeschreibung!: string
      public ProduktCode!: string
      public ProduktName!: string
      public ProduktTyp!: string
      public RelevanteZeitscheibe!: boolean
      public Werte!: ResponseGPVertraegeVertragszeitscheibenWerte
      public ZeitscheibeBis!: DateString
      public ZeitscheibenGrund!: ResponseGPVertraegeVertragszeitscheibenZeitscheibenGrund
      public ZeitscheibeVon!: DateString
    }
    export class ResponseGPVertraegeVertragsstatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeLieferstelleAdresse {
      public Hausnummer!: string
      public Land!: string
      public Ort!: string
      public PLZ!: string
      public Strasse!: string
    }
    export class ResponseGPVertraegeLieferstelle {
      public Adresse!: ResponseGPVertraegeLieferstelleAdresse
      public MarktlokationsId!: string
    }
    export class ResponseGPVertraegeKundenart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeAbweichenderRechnungsempfaengerAnrede {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeAbweichenderRechnungsempfaengerAdresse {
      public Hausnummer!: string
      public Land!: string
      public Ort!: string
      public PLZ!: string
      public Postfach!: string
      public Strasse!: string
    }
    export class ResponseGPVertraegeAbweichenderRechnungsempfaenger {
      public Adresse!: ResponseGPVertraegeAbweichenderRechnungsempfaengerAdresse
      public Anrede!: ResponseGPVertraegeAbweichenderRechnungsempfaengerAnrede
      public Name1_Nachname!: string
      public Name2_Vorname!: string
    }
    export class ResponseGPVertraege {
      public AbweichenderRechnungsempfaenger!: ResponseGPVertraegeAbweichenderRechnungsempfaenger
      public AngebotGueltigAb!: DateString
      public AnzahlUngeleseneKundendokumente!: number
      public Kundenart!: ResponseGPVertraegeKundenart
      public Lieferstelle!: ResponseGPVertraegeLieferstelle
      public Sparte!: ResponseGPVertraegeSparte
      public Vertragsbeginn!: DateString
      public Vertragsende!: DateString
      public Vertragsstatus!: ResponseGPVertraegeVertragsstatus
      public Vertragszeitscheiben!: ResponseGPVertraegeVertragszeitscheiben[]
      public VK!: ResponseGPVertraegeVK
      public Werte!: ResponseGPVertraegeWerte
      public Zaehlerdaten!: ResponseGPVertraegeZaehlerdaten
    }
    export class ResponseGPKontaktdaten {
      public Anzeigetext!: string
      public Code!: string
      public Wert!: string
    }
    export class ResponseGPGP {
      public Anzeigetext!: string
      public Art!: string
      public ReferenzID!: string
      public Typ!: string
    }
    export class ResponseGPAnschrift {
      public Hausnummer!: string
      public Land!: string
      public NameCo!: string
      public Ort!: string
      public PLZ!: string
      public Postfach!: string
      public Strasse!: string
    }
    export class ResponseGPAnrede {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGP {
      public Anrede!: ResponseGPAnrede
      public Anschrift!: ResponseGPAnschrift
      public Bonitaet!: number
      public Geburtsdatum!: DateString
      public GP!: ResponseGPGP
      public Kontaktdaten!: ResponseGPKontaktdaten[]
      public Name1_Nachname!: string
      public Name2_Vorname!: string
      public Titel!: string
      public Vertraege!: ResponseGPVertraege[]
      public Werte!: ResponseGPWerte
      public ZweiterVertragspartner!: ResponseGPZweiterVertragspartner
    }
    export class Response {
      public GP!: ResponseGP[]
      public Referenzen!: ResponseReferenzen
    }
  }
  export namespace GetVertragsliste {
    export class RequestWerte { }
    export class Request {
      public GP?: string[]
      public NurGPDaten?: boolean
      public Username?: string
      public VK?: string[]
      public Werte?: RequestWerte
    }
    export class ResponseGPZweiterVertragspartner {
      public Nachname!: string
      public Vorname!: string
    }
    export class ResponseGPWerte { }
    export class ResponseGPVertragsangeboteWerte { }
    export class ResponseGPVertragsangeboteLieferstellenadresse {
      public Hausnummer!: string
      public Land!: string
      public Ort!: string
      public PLZ!: string
      public Strasse!: string
    }
    export class ResponseGPVertragsangebote {
      public AngebotGueltigAb!: DateString
      public AngebotGueltigBis!: DateString
      public Angebotsdatum!: DateString
      public Angebotsname!: string
      public Angebotsnummer!: string
      public ArbeitsauftragCode!: string
      public ArbeitsauftragId!: string
      public GewuenschterVertragsbeginn!: DateString
      public Lieferstellenadresse!: ResponseGPVertragsangeboteLieferstellenadresse
      public Produktname!: string
      public Werte!: ResponseGPVertragsangeboteWerte
    }
    export class ResponseGPVertraegeWerte {
      public IST_RLM?: string
      public IST_IMS?: string
      public IST_SPOT?: string
    }
    export class ResponseGPVertraegeVK {
      public Anzeigetext!: string
      public ReferenzID!: string
    }
    export class ResponseGPVertraegeVertragsstatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGPVertraegeProduktwechselangeboteWerte { }
    export class ResponseGPVertraegeProduktwechselangebote {
      public AngebotGueltigAb!: DateString
      public AngebotGueltigBis!: DateString
      public Angebotsdatum!: DateString
      public Angebotsname!: string
      public Angebotsnummer!: string
      public ArbeitsauftragCode!: string
      public ArbeitsauftragId!: string
      public GewuenschtesWechseldatum!: DateString
      public Produktname!: string
      public Werte!: ResponseGPVertraegeProduktwechselangeboteWerte
    }
    export class ResponseGPVertraegeLieferstelleAdresse {
      public Hausnummer!: string
      public Land!: string
      public Ort!: string
      public PLZ!: string
      public Strasse!: string
    }
    export class ResponseGPVertraegeLieferstelle {
      public Adresse!: ResponseGPVertraegeLieferstelleAdresse
      public MarktlokationsId!: string
    }
    export class ResponseGPVertraege {
      public AnzahlUngeleseneKundendokumente!: number
      public DokumentenVersandArt!: string
      public Jahresverbrauch!: number
      public Lieferstelle!: ResponseGPVertraegeLieferstelle
      public Produktname!: string
      public Produktwechselangebote!: ResponseGPVertraegeProduktwechselangebote[]
      public Sparte!: ResponseGPVertraegeSparte
      public Vertragsbeginn!: DateString
      public Vertragsende!: DateString
      public Vertragsstatus!: ResponseGPVertraegeVertragsstatus
      public VK!: ResponseGPVertraegeVK
      public Werte!: ResponseGPVertraegeWerte
      public Zaehlernummer!: string
    }
    export class ResponseGPKontaktdaten {
      public Anzeigetext!: string
      public Code!: string
      public Wert!: string
    }
    export class ResponseGPGP {
      public Anzeigetext!: string
      public Art!: string
      public ReferenzID!: string
      public Typ!: string
    }
    export class ResponseGPAnschrift {
      public Hausnummer!: string
      public Land!: string
      public NameCo!: string
      public Ort!: string
      public PLZ!: string
      public Postfach!: string
      public Strasse!: string
    }
    export class ResponseGPAnrede {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseGP {
      public Anrede!: ResponseGPAnrede
      public Anschrift!: ResponseGPAnschrift
      public Geburtsdatum!: DateString
      public GP!: ResponseGPGP
      public Kontaktdaten!: ResponseGPKontaktdaten[]
      public Name1_Nachname!: string
      public Name2_Vorname!: string
      public Titel!: string
      public Username!: string
      public Vertraege!: ResponseGPVertraege[]
      public Vertragsangebote!: ResponseGPVertragsangebote[]
      public Werte!: ResponseGPWerte
      public ZweiterVertragspartner!: ResponseGPZweiterVertragspartner
    }
    export class Response {
      public GP!: ResponseGP[]
    }
  }
  export namespace UpdateStammdaten {
    export class RequestWerte { }
    export class RequestNeueRechnungsanschriftAdresse {
      public Hausnummer?: string
      public Land?: string
      public Ort?: string
      public PLZ?: string
      public Postfach?: string
      public Strasse?: string
    }
    export class RequestNeueRechnungsanschrift {
      public Adresse?: RequestNeueRechnungsanschriftAdresse
      public Anrede?: string
      public Name1_Nachname?: string
      public Name2_Vorname?: string
    }
    export class RequestNeueKontaktdaten {
      public Email?: string
      public Faxnummer?: string
      public Telefonnummer?: string
    }
    export class RequestAnhaengeDateiId {
      public DateiId?: string
      public SystemId?: string
    }
    export class RequestAnhaenge {
      public DateiId?: RequestAnhaengeDateiId
      public Daten?: string
      public Name?: string
      public Typ?: string
    }
    export class Request {
      public Anhaenge?: RequestAnhaenge[]
      public GP?: string
      public NeueKontaktdaten?: RequestNeueKontaktdaten
      public NeueRechnungsanschrift?: RequestNeueRechnungsanschrift
      public NeuesGeburtsdatum?: DateString
      public VK?: string
      public Werte?: RequestWerte
    }
    export class Response { }
  }
}

export namespace Vorversorger {
  export namespace GetVorversorger {
    export class Request {
      public Code?: string
      public Name?: string
      public Sparte?: string
      public Tenant?: string
    }
    export class ResponseVorversorger {
      public Code!: string
      public Name!: string
      public Transaktionsgrundwechsel!: string
    }
    export class Response {
      public Vorversorger!: ResponseVorversorger[]
    }
  }
}

export namespace Zaehlerstand {
  export namespace CreateZaehlerstand {
    export class RequestZaehlerstaende {
      public Zaehlerstand?: number
      public Zaehlwerk?: string
    }
    export class RequestWerte { }
    export class RequestDateianhaenge {
      public Dateiname?: string
      public DateiTyp?: string
      public Daten?: string
    }
    export class Request {
      public Ablesedatum?: DateString
      public Dateianhaenge?: RequestDateianhaenge[]
      public DetectionId?: string
      public Plausibilisierungsgrund?: string
      public Plausibilisierungskommentar?: string
      public VK?: string
      public Werte?: RequestWerte
      public Zaehlerstaende?: RequestZaehlerstaende[]
    }
    export class ResponsePlausibilisierungsgruende {
      public Anzeigetext!: string
      public Code!: string
    }
    export class Response {
      public Plausibilisierungsgruende!: ResponsePlausibilisierungsgruende[]
    }
  }
  export namespace DetectZaehlerstand {
    export class RequestZaehlerstandsbild {
      public Dateiname?: string
      public DateiTyp?: string
      public Daten?: string
    }
    export class RequestWerte { }
    export class Request {
      public VK?: string
      public Werte?: RequestWerte
      public Zaehlerstandsbild?: RequestZaehlerstandsbild
    }
    export class ResponseZaehlerstandsbildAusschnitt {
      public Dateiname!: string
      public DateiTyp!: string
      public Daten!: string
    }
    export class ResponseZaehlerstaende {
      public Zaehlerstand!: number
      public Zaehlwerk!: string
    }
    export class ResponseWerte { }
    export class Response {
      public DetectionId!: string
      public Werte!: ResponseWerte
      public Zaehlerstaende!: ResponseZaehlerstaende[]
      public ZaehlerstandsbildAusschnitt!: ResponseZaehlerstandsbildAusschnitt
    }
  }
  export namespace GetZaehlerstaende {
    export class Request {
      public VK?: string
    }
    export class ResponseZaehlerZaehlwerkZaehlwerksart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerZaehlwerkZaehlerstandStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerZaehlwerkZaehlerstandAblesegrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerZaehlwerkZaehlerstandAbleseart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerZaehlwerkZaehlerstand {
      public Ableseart!: ResponseZaehlerZaehlwerkZaehlerstandAbleseart
      public Ablesedatum!: DateString
      public Ablesegrund!: ResponseZaehlerZaehlwerkZaehlerstandAblesegrund
      public Status!: ResponseZaehlerZaehlwerkZaehlerstandStatus
      public Verbrauch!: number
      public Zaehlerstand!: number
    }
    export class ResponseZaehlerZaehlwerk {
      public Zaehlerstand!: ResponseZaehlerZaehlwerkZaehlerstand[]
      public Zaehlwerksart!: ResponseZaehlerZaehlwerkZaehlwerksart
    }
    export class ResponseZaehlerZaehlerart {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehlerSparte {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseZaehler {
      public Sparte!: ResponseZaehlerSparte
      public Zaehlerart!: ResponseZaehlerZaehlerart
      public Zaehlernummer!: string
      public Zaehlwerk!: ResponseZaehlerZaehlwerk[]
    }
    export class ResponseWerte { }
    export class ResponseOffenerAbleseauftragAblesegrund {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseOffenerAbleseauftrag {
      public AblesedatumSoll!: DateString
      public Ablesegrund!: ResponseOffenerAbleseauftragAblesegrund
      public ZuordnungsdatumMax!: DateString
      public ZuordnungsdatumMin!: DateString
    }
    export class ResponseJahresverbraeuche {
      public DatumAb!: DateString
      public DatumBis!: DateString
      public Verbrauch!: number
    }
    export class Response {
      public Jahresverbraeuche!: ResponseJahresverbraeuche[]
      public OffenerAbleseauftrag!: ResponseOffenerAbleseauftrag
      public Werte!: ResponseWerte
      public Zaehler!: ResponseZaehler[]
    }
  }
}

export namespace Zahlung {
  export namespace CreateRatenplan {
    export class Request {
      public AnzahlRaten?: number
      public FaelligkeitErsteRate?: DateString
      public Rate?: number
      public VK?: string
      public Vorschau?: boolean
      public Waehrung?: string
    }
    export class ResponseRatenplan {
      public Betrag!: number
      public FaelligZum!: DateString
    }
    export class Response {
      public Ratenplan!: ResponseRatenplan[]
      public Waehrung!: string
    }
  }
  export namespace GetRatenplan {
    export class Request {
      public VK?: string
    }
    export class ResponseVK {
      public AnzeigeText!: string
      public ReferenzID!: string
    }
    export class ResponseVertragsstatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseStatus {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseRatenplan {
      public Betrag!: number
      public FaelligZum!: DateString
    }
    export class ResponseRatenkosten {
      public GebuehrRatenplan!: number
      public RateDefault!: number
      public RateMax!: number
      public RateMin!: number
    }
    export class ResponseRatenfaelligkeit {
      public FaelligkeitErsteRateMax!: DateString
      public FaelligkeitErsteRateMin!: DateString
    }
    export class ResponseRatenananzahl {
      public AnzahlRatenMax!: number
      public AnzahlRatenMin!: number
    }
    export class Response {
      public OffenerAbschlag!: number
      public Ratenananzahl!: ResponseRatenananzahl
      public Ratenfaelligkeit!: ResponseRatenfaelligkeit
      public Ratenkosten!: ResponseRatenkosten
      public Ratenplan!: ResponseRatenplan[]
      public Ratenplannummer!: string
      public Status!: ResponseStatus
      public SummeOffeneForderungen!: number
      public Vertragsstatus!: ResponseVertragsstatus
      public VK!: ResponseVK
      public Waehrung!: string
    }
  }
  export namespace GetZahlungsinformation {
    export class Request {
      public VK?: string[]
    }
    export class ResponseVertragZahlungsweise {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseVertragZahlungsinformationenZahlungsrichtung {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseVertragZahlungsinformationenSEPA {
      public Bankname!: string
      public BIC!: string
      public GlaeubigerIdentifikationsnummer!: string
      public IBAN!: string
      public Kontoinhaber!: string
      public Mandatsnummer!: string
    }
    export class ResponseVertragZahlungsinformationenOnlineBezahldienstDienstleister {
      public Anzeigetext!: string
      public Code!: string
    }
    export class ResponseVertragZahlungsinformationenOnlineBezahldienst {
      public Benutzername!: string
      public Dienstleister!: ResponseVertragZahlungsinformationenOnlineBezahldienstDienstleister
    }
    export class ResponseVertragZahlungsinformationen {
      public OnlineBezahldienst!: ResponseVertragZahlungsinformationenOnlineBezahldienst
      public SEPA!: ResponseVertragZahlungsinformationenSEPA
      public Zahlungsrichtung!: ResponseVertragZahlungsinformationenZahlungsrichtung
    }
    export class ResponseVertragWerte { }
    export class ResponseVertragVK {
      public AnzeigeText!: string
      public ReferenzID!: string
    }
    export class ResponseVertrag {
      public VK!: ResponseVertragVK
      public Werte!: ResponseVertragWerte
      public Zahlungsinformationen!: ResponseVertragZahlungsinformationen[]
      public Zahlungsweise!: ResponseVertragZahlungsweise
    }
    export class Response {
      public Vertrag!: ResponseVertrag[]
    }
  }
  export namespace UpdateZahlungsinformation {
    export class RequestWerte { }
    export class RequestNeueZahlungsinformationSEPA {
      public BIC?: string
      public Datenherkunft?: string
      public IBAN?: string
      public Kontoinhaber?: string
    }
    export class RequestNeueZahlungsinformationOnlineBezahldienst {
      public Benutzername?: string
      public Dienstleister?: string
      public Token?: string
    }
    export class RequestNeueZahlungsinformation {
      public OnlineBezahldienst?: RequestNeueZahlungsinformationOnlineBezahldienst
      public SEPA?: RequestNeueZahlungsinformationSEPA
      public Zahlungsrichtung?: string
    }
    export class RequestDateiAnhaenge {
      public Dateiname?: string
      public DateiTyp?: string
      public Daten?: string
    }
    export class Request {
      public DateiAnhaenge?: RequestDateiAnhaenge[]
      public NeueZahlungsinformation?: RequestNeueZahlungsinformation
      public VK?: string
      public Werte?: RequestWerte
    }
  }
}
