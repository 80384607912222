import * as DI from '@/api/digital-interface/client'

/**
 * Token mismatch interceptor.
 *
 * Handles token mismatch between open and closed area.
 */
export default class TokenMismatch implements DI.Interceptor {
  /**
   * Instantiates a new token mismatch interceptor.
   */
  public constructor (router: any) {
    this.router = router
  }

  /**
   * @inheritdoc
   */
  public async intercept (context: DI.InterceptorContext) {
    if (this.isTokenMismatched(context)) {
      throw this.createError(context);
    }
    await context.proceed();
  }

  /**
   * Returns true if the token is mismatched.
   */
  protected isTokenMismatched (context: DI.InterceptorContext) {
    const token = context.request.token;
    const isClosedArea = !this.router.isOpenArea;
    return token?.isAnonymous() && isClosedArea;
  }

  /**
   * Creates error.
   */
  protected createError (context: DI.InterceptorContext) {
    return new DI.ErrorTokenExpired(context.request.token!);
  }

  /**
   * Router.
   */
  protected router: any;
}
