<template>
  <div class="maintenance">
    <section>
      <div class="v-center">
        <cso-grid-row>
          <cso-grid-column xs="12"
                            s="7"
                            m="5">
            <div v-if="isMessageFromDI" class="copybox-body" v-html="markedText"></div>
            <div v-else class="copybox-body">
              <p>{{ $t('maintenance.paragraph1') }}</p>
              <p>{{ $t('maintenance.paragraph2') }}</p>
              <p>{{ $t('maintenance.paragraph3') }}</p>
            </div>
          </cso-grid-column>
        </cso-grid-row>
      </div>
    </section>
  </div>
</template>

<script>
import Utils from '@/common/Utils'
export default {
  name: 'CsoMaintenance',
  data () {
    return {
      markedText: ''
    }
  },
  props: {
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    isMessageFromDI () {
      return this.message.length > 0
    }
  },
  methods: {
    async prepareTexts () {
      this.markedText = await Utils.replaceMarkdown(this.message)
    }
  },
  async created () {
    await this.prepareTexts()
  }
}
</script>
<style lang="scss">
.maintenance {
  background-image: url('/static/images/maintenance.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 90vh;
  min-height: 400px;
  width: 100%;

  .copybox-title {
    color: $white;
    font-family: $font-family-regular;
    font-size: 24px;
    line-height: 33.599999999999994px;
    margin: 0px;
  }

  .copybox-body {
    background-color: $white;
    padding: 20px;
    font-family: $font-family-light;
    min-width: 390px;
  }

  .v-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
