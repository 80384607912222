<template>
  <div class="cso-box"
       :class="{'has-overlay': hasOverlay, 'no-padding': noPadding}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'cso-box',
  props: {
    hasOverlay: {
      type: Boolean,
      required: false,
      default: false
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang="scss">
.cso-box {
  background-color: $white;
  padding: 20px;
  margin-top: 10px;
  color: $blue-night;

  &.no-padding {
    padding: 0;
  }

  @include box-shadow(3);

  @include print-view {
    padding: 0 20px;
  }

  @include md {
    padding: 10px;
  }
}
</style>
