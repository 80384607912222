<template>
  <cso-component-state :componentState="state">
    <template v-slot:ready
         v-if="items.length > 0">
      <nav class="vue-nav">
        <ul>
          <li v-for="(item, index) in items"
              :key="index">
            <cso-link :link="item.url"
                      :iconClass="item.iconClass"
                       class="navigation--link"
                      :label="$t('navigation.labels.' + item.title)">
            </cso-link>
          </li>
        </ul>
      </nav>
    </template>
  </cso-component-state>
</template>
<script lang="ts">
import { Subscription } from 'rxjs'

import store, { type State } from '@/store'
import StaticUrls from '@/common/StaticUrls'
import ComponentsStates from '@/common/ComponentsStates'
import LoggingService from '@/common/LoggingService'

import { type Type, type Item, items, menuEmpty, menuNoContract, menuNoncomm, menuDefault } from './Model'

import CsoComponentState from '@/components/core/composition/CsoComponentState.vue'

let subscription: Subscription

function filterItems (titles: string[]) {
  return items.filter(item => titles.includes(item.title))
}

export default {
  name: 'CsoNavigation',

  components: {
    CsoComponentState
  },

  data () {
    return {
      state: ComponentsStates.unavailable,
      StaticUrls,
      vk: '',
      type: 'EMPTY' as Type
    }
  },

  computed: {
    items (): Item[] {
      if (this.type === 'EMPTY') {
        return filterItems(menuEmpty)
      } else if (this.type === 'NO_CONTRACT') {
        return filterItems(menuNoContract)
      } else if (this.type === 'NONCOMM') {
        return filterItems(menuNoncomm)
      } else if (this.type === 'DEFAULT') {
        return filterItems(menuDefault)
      } else {
        throw new Error(`Unhandled menu type '${this.type}'`)
      }
    }
  },

  async created () {
    subscription = store.subscribe(this.observeStore)
    await this.fetch()
  },

  unmounted () {
    subscription.unsubscribe()
  },

  methods: {
    async observeStore (state: State) {
      if (state.vk !== this.vk) {
        this.setType()
      }
    },

    async fetch () {
      this.state = ComponentsStates.silentLoading
      try {
        await store.fetchVertragsliste()
        this.state = ComponentsStates.ready
      } catch (error) {
        this.state = ComponentsStates.simpleError
      }
    },

    setType () {
      this.vk = store.vk
      if (!store.hasGP) {
        this.type = 'EMPTY'
      } else if (!store.hasVK || !store.VK) {
        this.type = 'NO_CONTRACT'
      } else if (store.VK.Sparte.Code === 'NONCOMM') {
        this.type = 'NONCOMM'
      } else {
        this.type = 'DEFAULT'
      }
      LoggingService.log(LoggingService.eventTypes.app, 'Set type for the navigation', this.type)
    }
  }
}
</script>

<style lang="scss">
.vue-nav {
  position: fixed;
  width: 266px;
  height: 100vh;
  padding-left: 0;
  z-index: 90;
  justify-content: center;

  &:hover {
    width: 266px;

    ul {

      li {

        span {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  @include lg {
    width: 60px;
  }

  .navigation-icon {
    position: absolute;
    height: 24px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: transparent;
  }

  ul {
    box-shadow: 0px 8px 16px 2px rgba(0, 0, 0, 0.12);
    list-style: none;
    padding: 0;
    margin-top: 0;
    background-color: $left-nav-box;
    gap: 16px;

    li {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        height: 60px;
        width: 100%;
        padding: 18px 19px;
        text-decoration: none;
        cursor: pointer;
        border-top: 1px solid $left-nav-border;
        color: $black;
        background-position: 100% 0;
        background-size: 200% 100%;
        line-height: 26px;

        &:hover {
          background-position: 0 0;
          background-color: $gray-light;
        }

        span {
          position: absolute;
          margin-left: 38px;
          font-size: 16px;

          @include md {
            visibility: visible;
            font-family: $font-family-regular;
          }

          @include lg {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
    .router-link-exact-active {
      color: $black;
      background-color: $yellow;

      .vf-icon {
        color: $black;
      }
    }
  }
}

@include right-side-nav {
  .vue-nav {
    display: none;
  }
}

@include mobile-menu {
  .vue-nav {
    display: none;
  }
}
</style>
