import * as Model from '../../core/1.3/model'

import Abschlaege from './Abschlaege'
import Account from './Account'
import Felder from './Felder'
import Dienstleister from './Dienstleister'
import Inhalt from './Inhalt'
import Kundendokumente from './Kundendokumente'
import Kundenkampagnen from './Kundenkampagnen'
import Opts from './Opts'
import Produkt from './Produkt'
import Prozesse from './Prozesse'
import Prozessinformation from './Prozessinformation'
import Validierung from './Validierung'
import Vertraege from './Vertraege'
import Vertragsliste from './Vertragsliste'

export {
  Inhalt,
  Felder,
  Kundendokumente,
  Prozesse,
  Prozessinformation,
  Vertraege,
  Vertragsliste,
  Account,
  Produkt,
  Validierung,
  Kundenkampagnen,
  Opts,
  Abschlaege,
  Dienstleister
}

/** Contract account. */
export type VK = Model.Vertrag.GetVertragsliste.ResponseGPVertraege

/** Reference. */
export type Referenzen = Model.Produkt.GetProdukte.ResponseReferenzen

/** Document. */
export type Dokument = Model.Produkt.GetProdukte.ResponseReferenzenDokumenteDokument & Referenzierbar

/** Media. */
export type Medium = Model.Produkt.GetProdukte.ResponseReferenzenMedienMedium & Referenzierbar

/** Text. */
export type Text = Model.Produkt.GetProdukte.ResponseReferenzenTexteText & Referenzierbar

/** Salutation code. */
export type AnredeCode = Anrede['Feld']

/** Salutation gender. */
export type AnredeGender = Anrede['Properties']['GESCHLECHT']

/** Salutation property. */
export type AnredeProperty = keyof Anrede['Properties']

/** Field. */
export type Feld = Model.Inhalte.GetFelder.ResponseProzessFelder

/** Order. */
export type Auftraege = Model.Auftraege.CreateNeuauftrag.Request

/** Move order. */
export type Umzug = Model.Vertrag.CreateUmzug.Request

/** City. */
export type Ort = Model.Adressen.GetOrte.ResponseOrte

/** Delivery start. */
export type Lieferbeginn = Model.Produkt.GetLieferbeginn.Response

/** Address. */
export type Adresse = Model.Adressen.GetAdresse.ResponseAdressen

/** Supplier. */
export type Vorversorger = Model.Vorversorger.GetVorversorger.ResponseVorversorger

/** Meter fee. */
export type Zaehlerkosten = Model.Netzentgelte.GetZaehlerkosten.Response

/** Load profile fee. */
export type LastgangKosten = Model.Vertrag.GetLastgangKosten.Response

/** Extra values. */
export type Werte = Record<string, string>

// Meter reading shared types.
export type RequestZaehlerstaende = Model.Zaehlerstand.GetZaehlerstaende.Response
export type Zaehlwerk = Model.Zaehlerstand.GetZaehlerstaende.ResponseZaehlerZaehlwerk
export type ZaehlwerkZaehlerstand = Model.Zaehlerstand.GetZaehlerstaende.ResponseZaehlerZaehlwerkZaehlerstand
export type ResponseJahresverbraeuche = Model.Zaehlerstand.GetZaehlerstaende.ResponseJahresverbraeuche

export type SimpleResponse = {
  success: string,
  message: string
}

/* User agent */
export interface UserAgent {
  /** Browser name. */
  browser: string
  /** Operating system. */
  os: string
  /** Device details. */
  device: string
  /** User agent string. */
  userAgent: string
}

/** User credentials. */
export interface UserCredentials {
  username: string
  password: string
  twoStepAuth: boolean
  checkOnlyUserId: boolean
  termsAndConditionsConfirmed: boolean
  browser: string
  os: string
}

/** User magiclink. */
export interface UserMagiclink {
  code: string
  termsAndConditionsConfirmed: boolean
  browser: string
  os: string
}

/** A price value. */
export interface Value {
  Netto: number
  Brutto: number
}

/** Referencable item. */
export interface Referenzierbar {
  /** Reference id. */
  ReferenzID: string
}

export interface ResponseReferenzenParameter {
  Properties: ResponseReferenzenParameterParameterProperties
}

export interface ResponseReferenzenParameterParameterProperties {
  LABEL?: string | undefined;
}

/** Validation fields. */
export interface ValidierungFelder {
  /** Phone number. */
  TELEFONNUMMER: string
  /** Post code. */
  PLZ: string
  /** City. */
  ORT: string
  /** Street. */
  STRASSE: string
  /** House number. */
  HAUSNUMMER: string
  /** Email. */
  EMAIL: string
  /** Iban. */
  IBAN: string
  /** Bic. */
  BIC: string
  /** Surname */
  NAME1_NACHNAME: string
  /** Name */
  NAME2_VORNAME: string
  /** Is business partner */
  IST_GEWERBE: boolean
  /** Birth date. */
  GEBURTSDATUM: string
  /** Contract account. */
  VK: string
}

/** Contact reason. */
export interface Kontaktgrund extends Feld {
  Properties: {
    /** Whether contact reason is hidden. */
    UNSICHTBAR: string
    /** Contact reason priority (lower value is higher priority). */
    PRIO: string
    /** Button to self serivice portal. */
    SELF_SERVICE_BUTTONTEXT: string
    /** Link to self service portal. */
    SELF_SERVICE_URL: string
    /** Text about self service portal. */
    SELF_SERVICE_INFOTEXT: string
    /** Status for workorder processing. */
    STATUS: string
  }
}

/** Parameter. */
export interface Parameter extends Model.Produkt.GetProdukte.ResponseReferenzenParameterParameter, Referenzierbar {
  Properties: ResponseReferenzenParameterParameterProperties
}

/** File identifiers that uniquely identify it inside digital interface. */
export interface DateiIds {
  /** System id. */
  SystemId: string
  /** File id. */
  DateiId: string
}

/** Represents structure of a file returned from digital interface. */
export interface DateiResponse {
  /** File name. */
  Name: string
  /** File type (pdf/txt/etc). */
  Typ: string
  /** File content. */
  Daten: string
}

/** Represents structure of a file sent to digital interface. */
export interface DateiRequest {
  /** File name. */
  Name: string
  /** File content. */
  Daten: string
}

/** Dashboard teaser. */
export interface Teaser {
  /** Teaser name. */
  Name: string
  /** Teaser header. */
  Ueberschrift: string | undefined
  /** Teaser description. */
  Bezeichnung: string
  /** Teaser text. */
  Text: string | undefined
  /** Teaser link text. */
  LinkText: string | undefined
  /** Teaser link url. */
  LinkUrl: string | undefined
  /** Teaser image ids. */
  BildIds?: DateiIds
  /** Teaser image url. */
  BildUrl: string
}

/** Meta tags. */
export interface MetaTags {
  /** Page title. */
  title: string
  /** Page description. */
  description: string
  /** Page keywords. */
  keywords: string
}


/** Item with related content.  */
export interface WithInhalteReferenzen {
  Inhalte: {
    ContentReferenzIds: string[]
    DokumenteReferenzIds: string[]
    MedienReferenzIds: string[]
    ParameterReferenzIds: string[]
    TextReferenzIds: string[]
  }
}

/** Item with references. */
export interface WithReferenzen {
  Referenzen: Referenzen
}

/** Salutation. */
export class Anrede extends Model.Inhalte.GetFelder.ResponseProzessFelder {
  // Codes.
  public static FRAU /***********/: AnredeCode = '0001'
  public static HERR /***********/: AnredeCode = '0002'
  public static FIRMA /**********/: AnredeCode = '0003'
  public static WITHOUT_TITLE /**/: AnredeCode = '0004'
  public static CUSTOMER /*******/: AnredeCode = '0005'
  public static LADIES /*********/: AnredeCode = '0006'
  public static GENTLEMAN /******/: AnredeCode = '0007'
  public static DIVERS /*********/: AnredeCode = '0008'

  /** Salutation code. */
  public declare Feld: '0001' | '0002' | '0003' | '0004' | '0005' | '0006' | '0007' | '0008' | ''
  /** Salutation text. */
  public declare Wert: string
  /** Salutation properties. */
  public declare Properties: {
    /** Whether salutation is available in authoried person process. */
    IST_VOLLMACHT: string
    /** Whether salutation is available in move in process. */
    IST_AUFTRAG_KSO: string
    /** Whether salutation is available in noncomm product. */
    IST_NONCOMM: string
    /** Whether salutation is available in register process. */
    IST_ACCOUNT: string
    /** Whether salutation is available in contact form process. */
    IST_KONTAKT: string
    /** Whether it's possible to add second contact person. */
    IST_ZWEITER_VERTRAGSPARTNER: string
    /** Template to be displayed. */
    ANZEIGETEXT: string
    /** Gender. */
    GESCHLECHT: 'FRAU' | 'HERR' | 'FIRMA' | 'FRAU|MANN' | 'LADIES' | 'GENTLEMAN' | 'DIVERS'
  }
}
/** Work orders */
export type ArbeitsauftragRequest = Model.Arbeitsauftraege.GetArbeitsauftrag.Request

/** Sort items by reference ascending. */
export function sortByReferenz (a: Referenzierbar, b: Referenzierbar) {
  return Number(a.ReferenzID) - Number(b.ReferenzID)
}

/** Creates empty references. */
export function emptyReferenzen (): Referenzen {
  return { Content: [], Dokumente: [], Medien: [], Parameter: [], Texte: [], Steuerelemente: [] }
}
