import * as Model from '../../core/1.3/model'
import { type WithInhalteReferenzen } from './types'

class VertraegeResponse extends Model.Vertrag.GetVertraege.Response { }

/**
 * Contracts helper.
 */
export default class Vertraege extends VertraegeResponse {
  /**
   * Creates empty contracts.
   */
  public static createEmpty () {
    return new Vertraege({
      GP: [],
      Referenzen: { Content: [], Dokumente: [], Medien: [], Parameter: [], Texte: [], Steuerelemente: [] }
    })
  }

  /**
   * Instantiates a new contract helper.
   */
  public constructor (vertraege: VertraegeResponse) {
    super()
    Object.assign(this, vertraege)
  }

  /**
   * Flat map of all contracts.
   */
  public get VK () {
    return this.GP.flatMap(gp => gp.Vertraege)
  }

  /**
   * Returns contract with given id.
   */
  public getVK (id: string) {
    return this.VK.find(vk => vk.VK.ReferenzID === id)
  }

  /**
   * Returns document with given name.
   */
  public getDokument (name: string) {
    const dokument = this.Referenzen.Dokumente.find(dokument => dokument.Dokument.Linkname === name)
    return dokument && dokument.Dokument
  }

  /**
   * Returns text with given category.
   */
  public getText (kategorie: string, item?: WithInhalteReferenzen) {
    const [hauptkategorie, unterkategorie] = kategorie.split('/')
    return this.Referenzen.Texte.find(text =>
      text.Text.Hauptkategorie === hauptkategorie &&
      text.Text.Unterkategorie === (unterkategorie || null) &&
      (!item || (item?.Inhalte?.TextReferenzIds && item.Inhalte.TextReferenzIds.includes(text.ReferenzID)))
    )
  }

  /**
   * Returns parameter with given category.
   */
  public getParameter (kategorie: string) {
    const [hauptkategorie, unterkategorie] = kategorie.split('/')
    return this.Referenzen.Parameter.find(parameter =>
      parameter.Parameter.Hauptkategorie === hauptkategorie &&
      parameter.Parameter.Unterkategorie === (unterkategorie || null)
    )
  }

  /**
   * Returns parameter with given category as boolean.
   */
  public getParameterBool (kategorie: string) {
    const parameter = this.getParameter(kategorie)
    return parameter?.Parameter.Wert === '1'
  }

  /**
   * Returns parameter with given category as date.
   */
  public getParameterDate (kategorie: string) {
    const parameter = this.getParameter(kategorie)
    const date = parameter?.Parameter.Wert || ''
    return new Date(date.split('.').reverse().join('-'))
  }

  /**
   * Returns related documents.
   */
  public getDokumente (item: WithInhalteReferenzen) {
    return this.Referenzen.Dokumente.filter(dokument =>
      item.Inhalte?.DokumenteReferenzIds?.includes(dokument.ReferenzID)
    )
  }
}
